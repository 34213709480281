<script setup>
import { ref } from "vue";
import groupImg from "@/assets/Group.png";
const emit = defineEmits(["close", "show"]);

const props = defineProps({
    show: Boolean,
    handleCancelOrder: Function,
});

const closeModal = () => {
    emit("close");
};

const show = async () => {
    emit("show");
};

</script>

<template>
    <div>
        <div v-if="show" class="fixed inset-0 flex items-center justify-center bg-black/50 z-50" @click="closeModal">
            <div class="w-[244px] h-[287px] bg-white  rounded-[20px] shadow-lg flex flex-col overflow-hidden">
                <div class="h-[88px] bg-[#408e58] flex-shrink-0"></div>

                <p class="text-sm font-semibold text-[#515151] text-center mt-4">
                    هل انت متأكد من إلغاء الطلب ؟
                </p>
                <div class="flex justify-center mt-2">
                    <img :src="groupImg" alt="Group Icon" class="w-12 my-2 h-12" />
                </div>
                <div class="flex flex-col items-center gap-4  flex-grow">
                    <div class="w-[220px] h-7 flex justify-center items-center bg-[#408e58] cursor-pointer rounded-lg"
                        @click="closeModal">
                        <p class="text-sm text-center text-white">الرجوع</p>
                    </div>

                    <button
                        class="w-[220px] h-7  pb-1 flex justify-center items-center bg-[#408e58]/20 cursor-pointer rounded-lg"
                        @click="handleCancelOrder">
                        <p class="text-sm text-center text-[#515151]">نعم, إلغاء الطلب</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
