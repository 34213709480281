import MainService from "./mainService";

class ApiService extends MainService {
    constructor() {
        super();
    }

}

export default ApiService;
