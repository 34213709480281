<template>
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center z-10">
        <div class="fixed inset-0 bg-black bg-opacity-50" @click="closeModal()"></div>
        <div class=" shadow-xl transform transition-all sm:max-w-sm w-9/12">
            <div class="bg-tazej-green-primary h-16 rounded-t-2xl">
                <div class="flex items-center justify-center h-full">
                    <slot name="header">
                    </slot>
                </div>
            </div>
            <div class="p-6 bg-white rounded-b-2xl">
                <div class="mt-4 flex items-center justify-center">
                    <slot name="body">
                    </slot>
                </div>
                <div class="mt-6 flex justify-end flex items-center justify-center ">
                    <slot name="footer">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['close']);
defineProps({
    isOpen: {
        type: Boolean,
        default: false
    }
});

function closeModal() {
    emit('close');
}
</script>

<style scoped>
/* Tailwind CSS is fully utilized; no additional styles needed */
</style>
