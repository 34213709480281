<template>
    <Transition>
        <div v-if="isVisible" ref="bottomElement"
            class="flex w-full flex-col z-10 items-center justify-center fixed bottom-0 ">
            <div v-if="useCartStore().products.length > 0 && (isVisible && $route.path !== '/login') && (isVisible && $route.path !== '/cart/confirmation') && (isVisible && $route.path !== '/cart') && (isVisible && $route.path !== '/map') && (isVisible && $route.path !== '/addresses')"
                class="chart-container text-white text-lg py-2 px-2 bg-tazej-lightGreen-primary flex rounded-lg flex-row justify-between mb-2">
                <div class="w-fit">
                    <svg class="w-9 h-9" viewBox="0 0 1657 1657" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1363.88 610.804L1210.61 976.201C1200.04 1001.48 1176.04 1017.63 1149.47 1017.63H1128.33C1128.33 1017.63 1129.19 1018.2 1129.62 1018.48H1018.48C1018.48 1018.48 1019.34 1017.91 1019.77 1017.63H829.928C829.928 1017.63 830.785 1018.2 831.214 1018.48H720.08C720.08 1018.48 720.938 1017.91 721.366 1017.63H700.225C673.656 1017.63 649.658 1001.34 639.087 976.201L485.815 610.804C477.958 592.234 478.53 571.665 486.815 553.809L451.675 447.39H304.259C295.117 447.39 287.832 439.962 287.832 430.963C287.832 421.963 295.26 414.393 304.259 414.393H463.674C470.816 414.393 477.101 418.964 479.387 425.82L512.098 524.954C522.383 518.384 534.525 514.67 547.095 514.67H1303.03C1325.03 514.67 1345.6 525.812 1358.03 544.524C1371.17 564.237 1373.45 589.092 1364.31 610.947L1363.88 610.804Z"
                            fill="white" />
                        <path
                            d="M801.502 1242.75C850.73 1242.75 890.638 1202.84 890.638 1153.61C890.638 1104.39 850.73 1064.48 801.502 1064.48C752.274 1064.48 712.367 1104.39 712.367 1153.61C712.367 1202.84 752.274 1242.75 801.502 1242.75Z"
                            fill="white" />
                        <path
                            d="M1025.63 1242.75C1074.85 1242.75 1114.76 1202.84 1114.76 1153.61C1114.76 1104.39 1074.85 1064.48 1025.63 1064.48C976.397 1064.48 936.49 1104.39 936.49 1153.61C936.49 1202.84 976.397 1242.75 1025.63 1242.75Z"
                            fill="white" />
                    </svg>
                </div>

                <div class="w-full flex items-center justify-center">
                    تمت إضافة {{ useCartStore().products?.length }} منتجات لسلتك
                </div>

                <div class="flex w-40 items-center justify-center border-r-2 border-white">
                    <router-link to="/cart"> عرض السلة </router-link>
                </div>
            </div>

            <div style="height: 60px;" class="w-full bg-[#FFFFFF] ">
                <div class="container ">
                    <!-- <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="absolute left-[139px] top-[-6px]" preserveAspectRatio="xMidYMid meet">
                        <circle cx="31" cy="31" r="31" fill="#408E58" />
                    </svg> -->
                    <div
                        class="flex flex-col item-home justify-start items-center w-[33px] absolute left-[293px] top-1">
                        <router-link to="/">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                                preserveAspectRatio="xMidYMid meet">
                                <path
                                    d="M6.77549 15.9403C6.95117 16.044 7.1659 16.0933 7.41966 16.0933C7.7027 16.0933 8.02478 16.0193 8.35174 15.891C8.37126 15.8861 8.39078 15.8762 8.4103 15.8663C8.64454 15.7726 8.8983 15.6097 9.1667 15.437C9.53758 15.2051 9.95727 14.9386 10.4648 14.7363C10.8698 14.5783 11.2749 14.4846 11.6897 14.45C12.6218 14.376 13.4123 14.6326 14.1102 14.8547C14.564 15.0027 14.9593 15.1261 15.2862 15.1261H15.296C15.6522 15.1261 15.9841 15.0619 16.3062 14.9336C16.6282 14.8053 16.9406 14.6178 17.2626 14.3513C17.7165 13.9763 18.063 13.5174 18.2826 12.9943C18.4924 12.4959 18.59 11.9481 18.5656 11.3658C18.5412 10.6502 18.3411 9.91496 17.9849 9.24877C17.6238 8.56283 17.1114 7.97066 16.5062 7.54133C15.662 6.93929 14.7299 6.58398 13.7344 6.48529C13.5538 6.46555 13.3684 6.46061 13.183 6.46061C12.8902 6.46061 12.5925 6.48035 12.2899 6.5297C11.8117 6.60372 11.3285 6.73202 10.8601 6.91461C10.2452 7.15642 9.6547 7.48705 9.10814 7.90157C8.55182 8.32103 8.05406 8.81451 7.62462 9.37214C6.80478 10.4282 6.18989 11.6372 5.89221 12.7722C5.73605 13.3594 5.75069 14.0701 5.92149 14.682C6.03861 15.0965 6.28261 15.6492 6.77549 15.9403ZM15.0081 10.0729C15.457 10.0729 15.8767 10.3492 16.0475 10.7934C16.1549 11.0746 16.15 11.3806 16.028 11.6569C15.906 11.9333 15.6864 12.1455 15.4131 12.2541C15.135 12.3626 14.8324 12.3577 14.5591 12.2343C14.2858 12.1109 14.076 11.8938 13.9686 11.6125C13.7442 11.0352 14.0321 10.3788 14.603 10.1518C14.7348 10.0976 14.8714 10.0729 15.0032 10.0729H15.0081Z"
                                    fill="#408E58" />
                                <path
                                    d="M6.05326 17.1988C7.10734 17.8206 8.32734 17.4751 8.95686 17.2136C9.30335 17.0705 9.62055 16.8682 9.93287 16.6708C10.4843 16.3204 11.0553 15.9602 11.797 15.9059C12.451 15.8565 13.0707 16.0539 13.671 16.2464C14.1931 16.4142 14.7299 16.5869 15.2814 16.5869H15.3009C16.3403 16.582 17.2773 16.2217 18.1703 15.4864C19.0487 14.761 19.6295 13.8086 19.8735 12.7229C19.8735 12.7229 19.8735 12.7131 19.8735 12.7081C19.9711 12.264 20.015 11.8051 19.9955 11.3264C19.932 9.3969 18.9072 7.49207 17.331 6.362C16.0036 5.40959 14.5786 5 13.1927 5C10.5673 5 8.05406 6.4607 6.48758 8.47903C5.55061 9.68312 4.84301 11.0797 4.49653 12.4022C4.38917 12.8068 4.33549 13.251 4.34037 13.7C4.35013 15.0768 4.89669 16.5227 6.0435 17.1988H6.05326ZM5.55549 12.6835C5.86782 11.4991 6.50222 10.2457 7.35134 9.15509C7.8003 8.57772 8.32246 8.05957 8.90318 7.62037C9.47415 7.18611 10.0939 6.84068 10.7381 6.589C11.231 6.39654 11.7385 6.25837 12.246 6.18435C12.7584 6.10539 13.2708 6.09552 13.7734 6.13994C14.8324 6.24357 15.8182 6.61861 16.7161 7.26013C17.3651 7.724 17.9117 8.35566 18.3021 9.09094C18.6827 9.80648 18.8975 10.5961 18.9219 11.3659C18.9414 12.0025 18.8389 12.5996 18.6095 13.1473C18.3655 13.7247 17.9898 14.2231 17.492 14.6376C17.1406 14.9288 16.7942 15.1361 16.4379 15.2792C16.2574 15.3483 16.0768 15.4025 15.8865 15.4371C15.701 15.4716 15.5058 15.4914 15.3058 15.4914C14.9202 15.4914 14.5006 15.3581 14.0126 15.2051C13.3489 14.9929 12.5925 14.7511 11.7238 14.8153C11.3432 14.8449 10.9723 14.9288 10.6014 15.0768C10.1281 15.2644 9.72303 15.521 9.36679 15.743C9.08375 15.9207 8.81534 16.0934 8.5567 16.197C8.53718 16.2069 8.51278 16.2168 8.49326 16.2217C8.12726 16.3648 7.76126 16.4438 7.43918 16.4438H7.41966C7.11222 16.4438 6.84382 16.3747 6.61934 16.2414C6.02886 15.8911 5.74094 15.2545 5.6043 14.7758C5.41398 14.1096 5.39934 13.325 5.57014 12.6785L5.55549 12.6835Z"
                                    fill="#408E58" />
                                <path
                                    d="M18.3949 15.7628C17.4384 16.5523 16.4331 16.9373 15.3058 16.9471H15.2863C14.6812 16.9471 14.1151 16.7645 13.5685 16.5918C12.9732 16.3994 12.412 16.2217 11.8264 16.2661C11.1676 16.3155 10.66 16.6362 10.1184 16.9817C9.79628 17.184 9.46444 17.3962 9.08868 17.5492C8.55187 17.7712 7.99555 17.8946 7.48315 17.9094C7.45387 17.9094 7.42459 17.9094 7.39531 17.9094C6.82923 17.9094 6.31683 17.7762 5.86786 17.5097C5.45794 17.2679 5.1017 16.9323 4.81378 16.5129C4.54538 16.128 4.34042 15.6838 4.1989 15.1854C4.15498 15.0324 4.11594 14.8696 4.08666 14.7117C3.97442 15.3433 3.96954 15.9355 4.08178 16.4734C4.16962 16.9027 4.33066 17.3024 4.55514 17.6479C4.76986 17.9785 5.01874 18.24 5.28714 18.4177C5.87275 18.7977 6.59987 19 7.39531 19C8.07851 19 8.78611 18.852 9.4986 18.5559C9.95732 18.3634 10.3477 18.1167 10.6942 17.8995C11.2164 17.5689 11.5336 17.3814 11.9044 17.3518C11.9386 17.3518 11.9728 17.3518 12.0069 17.3518C12.3632 17.3518 12.7682 17.4801 13.2367 17.6331C13.832 17.8255 14.5104 18.0427 15.2814 18.0427H15.3107C16.0183 18.0427 16.7064 17.9045 17.3554 17.6479H17.3749C18.9365 17.0162 19.9516 15.516 19.9565 13.8185C19.9565 13.7593 19.9565 13.705 19.9565 13.6458C19.6246 14.46 19.1025 15.1755 18.3949 15.7578V15.7628Z"
                                    fill="#408E58" />
                            </svg>
                            <p
                                class="self-stretch flex-grow-0 flex-shrink-0 w-[33px] text-[10px] font-semibold text-center text-[#515151]">
                                الرئيسية
                            </p>
                        </router-link>
                    </div>

                    <router-link to="/my-orders"
                        class="flex flex-col  item-order justify-start items-center w-[31px] absolute left-[230px] top-1">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="flex-grow-0 flex-shrink-0 w-6 h-6 relative" preserveAspectRatio="xMidYMid meet">
                            <path
                                d="M8.4326 5H5.73534C4.77694 5 4 5.78349 4 6.74998V9.47001C4 10.4365 4.77694 11.22 5.73534 11.22H8.4326C9.391 11.22 10.1679 10.4365 10.1679 9.47001V6.74998C10.1679 5.78349 9.391 5 8.4326 5Z"
                                fill="#408E58" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.0126 9.47496C9.0126 9.79668 8.75157 10.0599 8.43254 10.0599H5.73528C5.41625 10.0599 5.16006 9.79668 5.15522 9.47496V6.75493C5.15522 6.43321 5.41625 6.17485 5.73528 6.16998H8.43254C8.75157 6.16998 9.0126 6.43321 9.0126 6.75493V9.47496Z"
                                fill="#408E58" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.1094 7.91503H13.252C12.933 7.91503 12.6719 7.6518 12.6719 7.33008C12.6719 7.00836 12.933 6.74513 13.252 6.74513H17.1094C17.4284 6.74513 17.6894 7.00836 17.6894 7.33008C17.6894 7.6518 17.4284 7.91503 17.1094 7.91503Z"
                                fill="#408E58" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13.252 10.25H19.4199C19.739 10.25 20 9.98676 20 9.66503C20 9.34331 19.739 9.08008 19.4199 9.08008H13.252C12.933 9.08008 12.6719 9.34331 12.6719 9.66503C12.6719 9.98676 12.933 10.25 13.252 10.25Z"
                                fill="#408E58" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.4326 12.78H5.73534C4.77824 12.78 4 13.5648 4 14.53V17.25C4 18.2152 4.77824 19 5.73534 19H8.4326C8.89181 19 9.33169 18.8148 9.66039 18.4882C9.98425 18.1616 10.1679 17.7131 10.1679 17.25V14.53C10.1679 13.5648 9.38969 12.78 8.4326 12.78Z"
                                fill="#408E58" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.0126 17.25C9.0126 17.5717 8.75157 17.8349 8.43254 17.8349H5.73528C5.41625 17.8349 5.16006 17.5717 5.15522 17.25V14.5299C5.15522 14.2082 5.41625 13.9498 5.73528 13.945H8.43254C8.75157 13.945 9.0126 14.2082 9.0126 14.5299V17.25Z"
                                fill="#408E58" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13.252 15.695H17.1094C17.4284 15.695 17.6894 15.4318 17.6894 15.1101C17.6894 14.7884 17.4284 14.5251 17.1094 14.5251H13.252C12.933 14.5251 12.6719 14.7884 12.6719 15.1101C12.6719 15.4318 12.933 15.695 13.252 15.695Z"
                                fill="#408E58" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13.252 16.8601H19.4199C19.739 16.8601 20 17.1233 20 17.445C20 17.7668 19.739 18.03 19.4199 18.03H13.252C12.933 18.03 12.6719 17.7668 12.6719 17.445C12.6719 17.1233 12.933 16.8601 13.252 16.8601Z"
                                fill="#408E58" />
                        </svg>
                        <p
                            class="self-stretch flex-grow-0 flex-shrink-0 w-[31px] text-[10px] font-semibold text-center text-[#515151]">
                            طلباتي
                        </p>
                    </router-link>

                    <!-- <div class="item-dev">
                        <div class="flex   flex-col justify-start items-center w-[52px] absolute left-[146px] top-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" class="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                                preserveAspectRatio="xMidYMid meet">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M15.4558 15.7994C14.91 15.7994 14.466 16.2486 14.466 16.8008C14.466 17.3529 14.91 17.8021 15.4558 17.8021C16.0016 17.8021 16.4456 17.3529 16.4456 16.8008C16.4456 16.2486 16.0016 15.7994 15.4558 15.7994ZM13.282 16.8008C13.282 15.5842 14.2533 14.6016 15.4558 14.6016C16.6584 14.6016 17.6297 15.5842 17.6297 16.8008C17.6297 18.0174 16.6584 19 15.4558 19C14.2533 19 13.282 18.0174 13.282 16.8008Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M7.54657 15.7994C7.00078 15.7994 6.55676 16.2486 6.55676 16.8008C6.55676 17.3529 7.00078 17.8021 7.54657 17.8021C8.09234 17.8021 8.53637 17.3529 8.53637 16.8008C8.53637 16.2486 8.09234 15.7994 7.54657 15.7994ZM5.3727 16.8008C5.3727 15.5842 6.344 14.6016 7.54657 14.6016C8.74913 14.6016 9.72043 15.5842 9.72043 16.8008C9.72043 18.0174 8.74913 19 7.54657 19C6.344 19 5.3727 18.0174 5.3727 16.8008Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.6539 6.28677C11.4087 6.20723 11.0849 6.19787 10.1553 6.19787H3.59203C3.26364 6.19787 3 5.93115 3 5.59893C3 5.26671 3.26364 5 3.59203 5H10.1553C10.1923 5 10.2293 5 10.2663 5C11.0433 5 11.5752 5 12.0192 5.14505C12.9212 5.43984 13.6288 6.15576 13.9249 7.07287C14.0682 7.52675 14.0682 8.06486 14.0682 8.84628V8.95858C14.0682 9.59962 14.0775 9.77743 14.1145 9.90845C14.2116 10.2126 14.4475 10.4512 14.7481 10.5495C14.873 10.5916 15.0534 10.5963 15.6871 10.5963H19.4012C19.7295 10.5963 19.9932 10.863 19.9932 11.1952V12.8376C19.9932 13.7454 19.9932 14.4847 19.9146 15.0696C19.8313 15.6825 19.6555 16.2113 19.2393 16.6324C18.6842 17.1939 17.9396 17.3249 17.0561 17.3717C16.7278 17.3904 16.4502 17.1331 16.4364 16.8009C16.4179 16.4686 16.6723 16.1879 17.0006 16.1739C17.8517 16.1317 18.1893 16.0054 18.4021 15.7855C18.564 15.6217 18.675 15.3878 18.7397 14.9105C18.8045 14.4145 18.8045 13.7547 18.8045 12.7955V11.7942H15.6824C15.6501 11.7942 15.6223 11.7942 15.5899 11.7942C15.095 11.7942 14.7065 11.7942 14.3781 11.6865C13.7167 11.4666 13.1987 10.9425 12.9813 10.2734C12.8749 9.9412 12.8749 9.55283 12.8749 9.04748C12.8749 9.01473 12.8749 8.98665 12.8749 8.9539C12.8749 8.01339 12.8657 7.68584 12.787 7.43785C12.6113 6.89038 12.1858 6.4599 11.6446 6.28209L11.6539 6.28677ZM3.58741 13.8015C3.9158 13.7828 4.19332 14.0402 4.20719 14.3724C4.24882 15.2333 4.3737 15.5749 4.59109 15.7902C4.80848 16.0054 5.14612 16.1364 5.99254 16.1785C6.32093 16.1973 6.5707 16.478 6.55682 16.8055C6.54295 17.1331 6.26081 17.3904 5.93704 17.3764C5.05361 17.3296 4.30895 17.1986 3.75392 16.6371C3.19889 16.0756 3.06938 15.3222 3.02313 14.4285C3.00462 14.0963 3.25901 13.8156 3.58741 13.8015ZM8.53637 16.8008C8.53637 16.4686 8.80007 16.2019 9.12846 16.2019H13.874C14.2024 16.2019 14.466 16.4686 14.466 16.8008C14.466 17.133 14.2024 17.3998 13.874 17.3998H9.12846C8.80007 17.3998 8.53637 17.133 8.53637 16.8008Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M3 8.79935C3 8.46713 3.26364 8.20042 3.59203 8.20042H8.33754C8.66594 8.20042 8.92958 8.46713 8.92958 8.79935C8.92958 9.13157 8.66594 9.39828 8.33754 9.39828H3.59203C3.26364 9.39828 3 9.13157 3 8.79935Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M3 11.1999C3 10.8676 3.26364 10.6009 3.59203 10.6009H6.75571C7.0841 10.6009 7.34774 10.8676 7.34774 11.1999C7.34774 11.5321 7.0841 11.7988 6.75571 11.7988H3.59203C3.26364 11.7988 3 11.5321 3 11.1999Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M16.2096 7.83091C15.8997 7.79816 15.502 7.79816 14.9146 7.79816H13.4761C13.1477 7.79816 12.8841 7.53145 12.8841 7.19923C12.8841 6.867 13.1477 6.60029 13.4761 6.60029H14.9469C15.4973 6.60029 15.9552 6.60029 16.3299 6.63773C16.7277 6.67984 17.0792 6.76406 17.4168 6.96527C17.7545 7.16647 17.995 7.44254 18.217 7.77476C18.4298 8.09295 18.6471 8.50004 18.9062 8.98667L19.9283 10.9098C20.0856 11.1999 19.9746 11.5649 19.6878 11.724C19.4011 11.8831 19.0403 11.7708 18.883 11.4807L17.8747 9.5856C17.5972 9.06153 17.4076 8.7106 17.2318 8.44856C17.0653 8.20057 16.9358 8.07423 16.8063 7.99468C16.6722 7.91514 16.5056 7.85899 16.2096 7.83091Z"
                                    fill="white" />
                            </svg>
                            <p
                                class="self-stretch flex-grow-0 flex-shrink-0 w-[52px] text-[10px] font-semibold text-center text-white">
                                توصيل سريع
                            </p>
                        </div>
                    </div> -->
                    <router-link :to="isAuthed ? '/user-profile-page' : `/gest-profile-page`"
                        class="flex flex-col   justify-start items-center w-[30px] absolute left-[84px] top-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="flex-grow-0 flex-shrink-0 w-6 h-6 relative" preserveAspectRatio="xMidYMid meet">
                            <path
                                d="M15.1236 8.88379C15.1236 11.028 13.7271 11.7455 12.0001 11.7455C10.2731 11.7455 8.87656 11.028 8.87656 8.88379C8.87656 6.73957 10.2731 5 12.0001 5C13.7271 5 15.1236 6.73957 15.1236 8.88379Z"
                                fill="#408E58" />
                            <path
                                d="M17 17.1478C15.6271 18.3075 13.8922 19 12 19C10.1078 19 8.37293 18.3075 7 17.1478C7 14.3653 9.64359 13.2181 12 13.2181C14.3564 13.2181 17 14.6698 17 17.1478Z"
                                fill="#408E58" />
                        </svg>
                        <p
                            class="self-stretch flex-grow-0 flex-shrink-0 w-[30px] text-[10px] font-semibold text-center text-[#515151]">
                            حسابي
                        </p>
                    </router-link>

                    <a href="https://wa.me/00966114501893" rel="noopener noreferrer" target="_blank"
                        class="flex flex-col  justify-start items-center w-8 absolute left-5 top-1">
                        <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="self-stretch flex-grow-0 flex-shrink-0 h-6 relative"
                            preserveAspectRatio="xMidYMid meet">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M19.3487 13.3685C19.1757 13.2732 18.3301 12.8202 18.1716 12.7582C18.013 12.6962 17.8977 12.6628 17.7728 12.8297C17.6479 13.0014 17.2972 13.3781 17.1915 13.4925C17.0858 13.6069 16.9801 13.6165 16.8071 13.5211C16.6342 13.4257 16.0769 13.2207 15.4283 12.6056C14.9238 12.124 14.5923 11.5422 14.4962 11.3658C14.4001 11.1894 14.4962 11.0988 14.5875 11.0129C14.6692 10.9366 14.7701 10.8174 14.8614 10.7173C14.9526 10.6172 14.9863 10.5456 15.0487 10.436C15.1112 10.3215 15.0872 10.2214 15.0487 10.1308C15.0103 10.0402 14.6884 9.17234 14.5587 8.81948C14.4242 8.46662 14.2752 8.51907 14.1743 8.5143C14.0734 8.5143 13.9533 8.49046 13.838 8.4857C13.7227 8.4857 13.5305 8.51907 13.3624 8.68597C13.1942 8.85286 12.7234 9.25817 12.6946 10.1165C12.6609 10.9748 13.2615 11.8236 13.3432 11.9428C13.4248 12.062 14.4818 13.9217 16.2402 14.6846C17.9986 15.4475 18.0082 15.2139 18.3301 15.1996C18.652 15.1805 19.3823 14.8181 19.5456 14.4176C19.709 14.017 19.7186 13.6689 19.6802 13.5926C19.6369 13.5163 19.5216 13.4687 19.3535 13.3781L19.3487 13.3685ZM16.1249 5C12.3294 5 9.25463 8.05177 9.25463 11.8188C9.25463 13.1063 9.61496 14.3127 10.2395 15.3426L9 19L12.8051 17.7936C13.79 18.3324 14.9238 18.6424 16.1249 18.6424C19.9204 18.6424 23 15.5906 23 11.8236C23 8.05654 19.9252 5.00477 16.1249 5.00477V5Z"
                                fill="#408E58" />
                        </svg>
                        <p
                            class="self-stretch flex-grow-0 flex-shrink-0 w-8 text-[10px] font-semibold text-center text-[#515151]">
                            واتساب
                        </p>
                    </a>
                </div>
            </div>

        </div>
    </Transition>
</template>

<script setup>
import { useCartStore } from "@/stores/cart.store";

const isVisible = ref(true);
const isAuthed = ref(false);

const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Check if scroll is near the bottom (within 50px of the bottom)
    if (scrollTop + windowHeight >= documentHeight - 50) {
        isVisible.value = false;
    } else {
        isVisible.value = true;
    }
};

onMounted(() => {
    window.addEventListener("scroll", handleScroll);
    if (localStorage.getItem("token")) {
        isAuthed.value = true;
    }
});

onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
});
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.container {
    width: calc(100% - 2rem);
    margin-right: 1rem;
    height: 52px;
    position: relative;
    border-radius: 8px;
    background-color: #eee;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chart-container {
    width: calc(100% - 2rem);
}

@media (min-width: 1024px) {
    .container {
        width: 100%;
    }
}

@media (min-width: 414px) and (max-width: 896px) {
    .container .item-home {
        margin-left: 20px;
    }
}

@media (min-width: 414px) and (max-width: 896px) {
    .container .item-order {
        margin-left: 10px;
    }
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
