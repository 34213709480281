<script setup>
import { ref, computed, watch } from "vue";
import orderService from "@/service/orderService";
import { useToast } from "vue-toastification";
import Spinner from "@/components/Spinner.vue";

const toast = useToast();
const props = defineProps({
    isVisible: {
        type: Boolean,
        required: true,
    },
    screens: {
        type: Array,
        required: true,
    },
    orderId: {
        type: [String, Number],
        required: true,
    },
});

const emit = defineEmits(["close"]);

const isLoading = ref(false);
const currentIndex = ref(0);
const startX = ref(0);
const isSwiping = ref(false);
const feedbackNote = ref("");
const screens = ref([...props.screens]); // إنشاء نسخة تفاعلية من screens

const currentScreen = computed(() => screens.value[currentIndex.value]);

const dots = computed(() =>
    screens.value.map((screen, index) => ({
        color: index === currentIndex.value ? "#408E58" : "#D1D1D1",
    }))
);

const closeModal = () => {
    emit("close");
};

const nextScreen = () => {
    if (currentIndex.value < screens.value.length - 1) {
        currentIndex.value++;
    }
};

const prevScreen = () => {
    if (currentIndex.value > 0) {
        currentIndex.value--;
    }
};

const startSwipe = (event) => {
    isSwiping.value = true;
    startX.value = event.touches ? event.touches[0].clientX : event.clientX;
};

const handleSwipe = (event) => {
    if (!isSwiping.value) return;

    const currentX = event.touches ? event.touches[0].clientX : event.clientX;
    const deltaX = currentX - startX.value;

    if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
            nextScreen();
        } else {
            prevScreen();
        }
        isSwiping.value = false;
    }
};

const endSwipe = () => {
    isSwiping.value = false;
};

const setStars = (stars) => {
    screens.value[currentIndex.value].stars = stars;
};

watch(
    () => screens.value[currentIndex.value].stars,
    (newValue) => {
        console.log("Stars updated:", newValue); // للتأكد من تحديث القيمة
    }
);

const submitRating = async () => {
    isLoading.value = true;
    const ratingData = {
        order_id: props.orderId,
        answers_data: screens.value
            .filter((screen) => !screen.showTextarea)
            .map((screen) => ({
                question_id: screen.questionId,
                rating: screen.stars.toString(),
            })),
        feedback_note: feedbackNote.value,
    };
    try {
        await orderService.addRating(ratingData);
        closeModal();
        toast.success("تم تقييم الطلب بنجاح");
    } catch (error) {
        console.error("Error submitting rating:", error);
        toast.error("حدث خطأ أثناء تقييم الطلب");
    } finally {
        isLoading.value = false;
    }
};
</script>

<template>
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        @click="closeModal" @mousedown="startSwipe" @mousemove="handleSwipe" @mouseup="endSwipe"
        @touchstart="startSwipe" @touchmove="handleSwipe" @touchend="endSwipe">
        <div class="w-[285px] h-[275px] relative overflow-hidden" @click.stop>
            <div class="w-full h-full rounded-[20px] bg-white"></div>

            <!-- الجزء العلوي الأخضر -->
            <div
                class="w-[285px] h-[88px] absolute left-0 top-0 rounded-tl-[20px] rounded-tr-[20px] bg-[#408e58] border-0 transform translateZ(0) shadow-[0_0_0_1px_#408e58]">
                <!-- الصورة -->
                <div v-if="currentScreen.image" class="flex justify-center items-center h-full">
                    <img :src="currentScreen.image" alt="Modal Image" class="w-12 h-12" />
                </div>
            </div>

            <!-- العنوان -->
            <p
                class="w-[185px] text-md font-semibold text-center text-[#515151] absolute left-1/2 transform -translate-x-1/2 top-[100px]">
                {{ currentScreen.question }}
            </p>

            <!-- النجوم -->
            <div
                class="flex justify-center items-center gap-5 absolute left-1/2 transform -translate-x-1/2 top-[164px]">
                <svg v-for="(star, index) in 5" :key="index" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg" class="flex-grow-0 cursor-pointer flex-shrink-0 w-6 h-6"
                    @click="setStars(index + 1)">
                    <path
                        d="M13.8162 2.85732L16.2662 8.03526L21.7397 8.86537C22.9451 9.04908 23.4273 10.5936 22.5542 11.485L18.5925 15.513L19.5242 21.2013C19.7328 22.4532 18.4687 23.4126 17.3935 22.8206L12.5 20.133L7.60649 22.8206C6.53135 23.4126 5.26725 22.4532 5.47576 21.2013L6.40755 15.513L2.44582 11.485C1.57268 10.6004 2.05486 9.04908 3.26032 8.86537L8.73376 8.03526L11.1838 2.85732C11.7246 1.71423 13.2819 1.71423 13.8162 2.85732Z"
                        :stroke="index < currentScreen.stars ? '#408E58' : '#408E58'"
                        :fill="index < currentScreen.stars ? '#408E58' : 'none'" stroke-miterlimit="10"
                        stroke-width="1.5" />
                </svg>
            </div>

            <!-- النقاط (SVG) -->
            <div
                class="flex justify-center mt-2 items-center gap-[4px] absolute left-1/2 transform -translate-x-1/2 top-[208px]">
                <svg v-for="(dot, index) in dots" :key="index" width="10" height="10" viewBox="0 0 10 10" fill="none"
                    xmlns="http://www.w3.org/2000/svg" class="flex-grow-0 flex-shrink-0">
                    <circle cx="5" cy="5" r="5" :fill="dot.color" />
                </svg>
            </div>

            <!-- مربع النص (textarea) -->
            <div v-if="currentScreen.showTextarea"
                class="w-[220px] h-24 absolute left-1/2 transform -translate-x-1/2 top-[95px] rounded-lg border border-[#d1d1d1]">
                <textarea v-model="feedbackNote"
                    class="w-full h-full p-2 rounded-lg resize-none border border-[#d1d1d1] focus:border-green-500 focus:ring-green-500"
                    placeholder="اكتب ملاحظتك"></textarea>
            </div>

            <!-- زر "تم, شكراً لك" -->
            <div v-if="currentScreen.showTextarea"
                class="w-[220px] h-9 absolute left-1/2 transform -translate-x-1/2 top-[200px] flex justify-center items-center gap-2 px-4 py-[11px] rounded-lg bg-[#408e58]">
                <button @click="submitRating" class="text-sm text-center text-white cursor-pointer"
                    :disabled="isLoading">
                    <span v-if="!isLoading">تم, شكراً لك</span>
                    <Spinner v-if="isLoading" :isLoading="isLoading" />
                </button>
            </div>
        </div>
    </div>
</template>
<style scoped>
textarea:focus {
    border-color: green;
    outline: none;
}
</style>
