<script setup>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import TabBar from "@/components/TabBar.vue";
import NavBar from "@/components/NavBar.vue";
import BackHome from "@/components/backHome.vue";

library.add(fas, fab, far);
defineProps({
    navBarStatus: {
        type: Boolean,
        default: true,
    },
    backHomeStatus: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: "title",
    },
});
</script>

<template>
    <div dir="rtl">
        <NavBar v-show="navBarStatus" />
        <BackHome :title="title" v-show="backHomeStatus" />

        <div class="mb-32">
            <slot></slot>
        </div>

        <TabBar />
    </div>
</template>
