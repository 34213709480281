import ApiService from './apiService';
import MainService from './mainService';


class DeliveryService extends MainService {
    constructor() {
        super();
    }




    async getAvailablePeriod(day, area) {

        return await ApiService.post('sales/area/get_available_period', {
            "params":
            {
                "date": day, //"2025-02-12"
                "area": area //1
            }
        });
    }

    async checkAreaCapacity(day, area, delivery_period) {

        return await ApiService.post('sales/area/check_area_capacity', {
            "params":
            {
                "date": day,
                "area": area,
                "delivery_period": delivery_period
            }
        });
    }


    async getGiftData(areaId) {

        return await ApiService.post('sale/gift/get_gift_data', {
            "params": {
                "area_id": areaId
            }
        });
    }


}



export default new DeliveryService();