<template>
    <div class="border border-gray-300 rounded-lg overflow-hidden w-90 m-2">
        <div class="bg-gray-100 px-4 py-2 cursor-pointer flex justify-between items-center" @click="toggleCollapse">
            <span>{{ title }}</span>
            <span v-if="isCollapsed">^</span>
            <span v-else>v</span>
        </div>
        <div class="border-t border-gray-300"></div>
        <div v-show="isCollapsed" class="px-4 py-3 bg-gray-100 custom-scrollbar max-h-120 overflow-y-scroll">
            <slot name="body">
            </slot>
        </div>
        <div v-show="isExtension" class="px-4 py-3 bg-gray-100 custom-scrollbar max-h-120 overflow-y-scroll">
            <slot name="extension">
            </slot>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    collapse: {
        type: Boolean,
        default: true
    }
    ,
    extension: {
        type: Boolean,
        default: false
    }
});

const isCollapsed = ref(props.collapse);
const isExtension = ref(props.extension);

function toggleCollapse() {
    isCollapsed.value = !isCollapsed.value;
}


</script>

<style scoped>
.custom-scrollbar::-webkit-scrollbar {
    display: none;
}

.custom-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
</style>