import MainService from './mainService';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { firebaseApp } from '../plugins/firebase'
import apiService from './apiService';
import { v7 as uuidV7 } from 'uuid';

class AuthService extends MainService {
    db = getFirestore(firebaseApp);

    constructor() {
        super();
    }

    generateOtp() {
        const randomNum = Math.random() * 9000
        return Math.floor(1000 + randomNum)
    }

    async getTheKey() {
        const docRef = doc(this.db, "encryption_keys", "key");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data().key;
        } else {
            console.log("No such document!");
        }
    }

    generateToken() {
        return uuidV7().split("-").join("");
    }

    async encryptAESGCM(plaintext) {
        // Decode the base64 key
        const encryptionKey = await this.getTheKey();

        const key = Uint8Array.from(atob(encryptionKey), c => c.charCodeAt(0));

        // Generate a random 12-byte IV
        const iv = window.crypto.getRandomValues(new Uint8Array(12));

        // Import the key for AES-GCM
        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            key,
            { name: 'AES-GCM', length: 256 },
            false,
            ['encrypt']
        );

        // Encrypt the plaintext
        const encodedPlaintext = new TextEncoder().encode(plaintext);
        const ciphertext = await window.crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },
            cryptoKey,
            encodedPlaintext
        );

        // Combine IV and ciphertext
        const combined = new Uint8Array(iv.byteLength + ciphertext.byteLength);
        combined.set(iv);
        combined.set(new Uint8Array(ciphertext), iv.byteLength);

        // Return the result as a Base64 string
        return btoa(String.fromCharCode(...combined));
    }


    async sendOtp(phone, otp) {

        return apiService.post('v3/sms/login/otp',
            {
                params: {
                    mobile: phone,
                    encrypted_code: await this.encryptAESGCM(otp),
                    body: `don't share this code with any one`
                }

            });
    }

    async signUp(data) {
        return apiService.post('api/v1/customer/login-or-signup',
            {
                params: {
                    "phones": [data.phone],
                    "new_user": {
                        "name": data.fullName,
                        "phone": data.phone
                    }
                }
            });
    }


    async signIn(data) {
        return apiService.post('api/v1/customer/login-or-signup',
            {
                params: {
                    phones: [data.phones],

                }

            });
    }


}




export default new AuthService();