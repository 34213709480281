<template>
    <div class="flex w-full h-20 text-white text-xl px-10 justify-between items-center bg-tazej-lightGreen-primary">
        <h2>{{ title }}</h2>
        <div @click="goBack" class="cursor-pointer">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.96">
                    <path
                        d="M6.50378 14.9206L10.738 18.8631C11.1051 19.2051 11.6834 19.185 12.0304 18.8179C12.3723 18.4508 12.3522 17.8725 11.9851 17.5255L9.42547 15.1368L20.6295 15.0212H20.6345C21.1374 15.0111 21.5397 14.5988 21.5347 14.1009C21.5296 13.5981 21.1173 13.1907 20.6144 13.1958L9.40536 13.3114L11.8795 10.9027C12.2416 10.5506 12.2466 9.97234 11.8946 9.61027C11.7135 9.4242 11.4671 9.32866 11.2258 9.33368C10.9995 9.33368 10.7782 9.4242 10.6022 9.59518L6.49875 13.593C6.31771 13.769 6.22217 14.0054 6.22217 14.2568C6.22217 14.5083 6.32777 14.7446 6.51383 14.9156L6.50378 14.9206Z"
                        fill="white" />
                </g>
            </svg>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const props = defineProps({
    title: String,
    // default: ''
});

const router = useRouter();

const goBack = () => {
    router.go(-1);
};
</script>