<template>
    <div class="ml-8 my-6">
        <input type="text" v-model="phoneNumber"
            :class="{ 'valid': isValid, 'invalid': !isValid && phoneNumber.length > 0 }" class="my-2"
            :placeholder="placeholder" @keyup="handleKeyup" />
        <div v-if="!isValid && phoneNumber.length > 0"
            :class="{ 'valid': isValid, 'invalid': !isValid && phoneNumber.length > 0 }" class="error-message mb-4">هناك
            خطأ
            في كتابة رقم
            الهاتف,
            رجاء قم بالمراجعة!</div>
    </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from 'vue';

const emit = defineEmits(['validation']);
const props = defineProps({
    placeholder: {
        type: String,
        default: 'رقم الجوال'
    },
    value: {
        type: Number,
        default: 0
    }
});
const phoneNumber = ref("");
const isValid = ref(false);

watch(phoneNumber, (newValue) => {
    isValid.value = /^((00966|\+966|05|5)\d{8,9})$/.test(newValue);
    emit('validation', isValid.value);
});
onMounted(() => {
    if (props.value.length > 0) {
        phoneNumber.value = props.value;
    }
});
const handleKeyup = () => {
    emit('input', phoneNumber.value);
};

</script>

<style scoped>
input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border: solid 1px #515151;
    border-radius: 8px;
}

input.valid,
input:focus.valid {
    border-color: green;
    color: green;
}

input.invalid,
input:focus.invalid {
    border-color: red;
    color: red;
}

input div.invalid {
    color: red;
}
</style>