<script setup>
import { ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue'
import Logo from '@/components/Logo.vue'
import UserList from '@/components/UserList.vue';
import { useAuthStore } from '@/stores/auth.store';
import Modal from '@/components/Modal.vue';
import Rating from '@/components/Rating.vue';
import PhoneInput from '@/components/PhoneInput.vue';
import StringInput from '@/components/StringInput.vue';
import { userService } from '@/service';
import { useProductsStore } from '@/stores/products.store';
const authStore = useAuthStore();

// Get the correct URL for the images
const locationIconUrl = new URL('@/assets/location_icon.png', import.meta.url).href;
const aboutIconUrl = new URL('@/assets/about_icon.png', import.meta.url).href;
const phoneIconUrl = new URL('@/assets/phone_icon.png', import.meta.url).href;
const trashIconUrl = new URL('@/assets/trash_icon.png', import.meta.url).href;
const logoutIconUrl = new URL('@/assets/logout_icon.png', import.meta.url).href;
const vatIconUrl = new URL('@/assets/VAT_Icon.png', import.meta.url).href;
const profileImageUrl = new URL('@/assets/profile_image.png', import.meta.url).href;
const editIconUrl = new URL('@/assets/edit_icon.png', import.meta.url).href;
const privacyPolicyIconUrl = new URL('@/assets/privacy_policy_icon.png', import.meta.url).href;
const exclamationMark = new URL('@/assets/exclamationMark.png', import.meta.url).href;

const openUpdateUserModel = ref(false);
const openDeleteAccountModel = ref(false);
const openInformationModel = ref(false);
const openLogOutModel = ref(false);
const newUserData = ref({});
const router = useRouter();
function reRoute(link) {
    router.push(link)
}

const listItems = ref([
    {
        icon: locationIconUrl,
        title: "عناويني",
        action: () => reRoute("/addresses"),
    },
    {
        icon: aboutIconUrl,
        title: "عن الشركة",
        action: () => reRoute("/about"),
    },
    {
        icon: phoneIconUrl,
        title: "920007058 تواصل معنا",
        action: () => alert("Calling 920007058"),
    },
    {
        icon: privacyPolicyIconUrl,
        title: "سياسة الخصوصية",
        action: () => reRoute("/privacy-policy"),
    },
    {
        icon: trashIconUrl,
        title: "حذف الحساب",
        action: () => openDeleteAccountModel.value = true,
    },
    {
        icon: logoutIconUrl,
        title: "تسجيل الخروج",
        action: () => openLogOutModel.value = true,
    },
]);

async function updateUserData() {
    let updatedUserName = null;
    let updatedUserPhone = null;
    if (newUserData.value.name) {
        updatedUserName = await userService.updateUserName({
            id: authStore?.userData?.id,
            name: newUserData.value.name
        });

    }

    if (newUserData.value.phone) {
        updatedUserPhone = await userService.updateUserPhone({
            id: authStore?.userData?.id,
            phone: newUserData.value.phone
        });

    }

    if (updatedUserName && updatedUserName?.data?.result?.name || updatedUserPhone && updatedUserPhone?.data?.result?.phone) {
        openUpdateUserModel.value = false;
        await useProductsStore().getProductsData();
        authStore.userData.name = updatedUserName?.data?.result?.name ? updatedUserName?.data?.result?.name : authStore?.userData?.name;
        authStore.userData.phone = updatedUserPhone?.data?.result?.phone ? updatedUserPhone?.data?.result?.phone : authStore?.userData?.phone;
        await authStore.setUserData(authStore.userData);
    } else {
        alert(updatedUserName?.data?.result?.message ?? updatedUserPhone?.data?.result?.message);
    }
}
function setNewName(name) {
    newUserData.value.name = name
}

function setNewPhone(phone) {
    newUserData.value.phone = phone
}

function deleteAccount() {
    userService.deleteAccount(authStore?.userData?.id);
    authStore.logout();
    router.push("/");
}

function logout() {
    authStore.logout();
    router.push("/");
}

</script>

<template>
    <AppLayout :nav-bar-status="false" :back-home-status="true" title="حسابي الشخصي">
        <Modal :isOpen="openUpdateUserModel" @close="openUpdateUserModel = false">
            <template v-slot:header>
                <div class="p-4">
                    <h2 class="text-xl text-white font-bold mb-4">تعديل بيانات الحساب</h2>
                </div>
            </template>
            <template v-slot:body>
                <div>
                    <!-- <Rating /> -->
                    <StringInput @validation="handleValidationStatus" placeholder="الأسم بالكامل" @input="setNewName"
                        :value="authStore?.userData?.name ?? ''" />
                    <PhoneInput @validation="handleValidationStatus" placeholder="رقم الدعوة (اختياري)"
                        :value="authStore?.userData?.phone" @input="setNewPhone" />
                </div>
            </template>
            <template v-slot:footer>
                <div class="w-full">

                    <div class="mb-5 w-full">
                        <button @click="updateUserData" class="bg-tazej-green-primary text-white w-full">تعديل</button>
                    </div>
                </div>
            </template>
        </Modal>

        <Modal :isOpen="openDeleteAccountModel" @close="openDeleteAccountModel = false">
            <template v-slot:header>
                <div class="p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="white">
                        <path
                            d="M9 3V4H4V6H5V20C5 21.1 5.9 22 7 22H17C18.1 22 19 21.1 19 20V6H20V4H15V3H9M7 6H17V20H7V6M9 8V18H11V8H9M13 8V18H15V8H13Z " />
                    </svg>
                </div>
            </template>
            <template v-slot:body>
                <div>
                    <h1> هل أنت متأكد من حذف الحساب ؟</h1>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex flex-col justify-center w-full">
                    <div class="mb-5 w-full">
                        <button @click="deleteAccount"
                            class="bg-tazej-greenWithTransparency-primary   w-full ">نعم</button>
                    </div>
                    <div class=" mb-5  w-full">
                        <button @click="openDeleteAccountModel = false"
                            class="bg-tazej-greenWithTransparency-primary   w-full ">لا</button>
                    </div>
                    <div class="w-full">
                        <button @click="openDeleteAccountModel = false; openInformationModel = true"
                            class="bg-tazej-green-primary text-white  w-full">أحتاج مساعدة</button>
                    </div>

                </div>
            </template>
        </Modal>

        <Modal :isOpen="openInformationModel" @close="openInformationModel = false">
            <template v-slot:header>
                <div class="p-4">
                    <img :src="exclamationMark" alt="" srcset="">
                </div>
            </template>
            <template v-slot:body>
                <div>
                    <h2 class="text-xl text-center mb-4">تواصل معنا للحصول على المساعدة</h2>
                    <div class="border-t border-gray-300 my-4"></div>
                    <div>
                        <span class="mr-2">📞</span>
                        <span class="mr-5 inline">
                            خدمة العملاء 850700029
                        </span>
                    </div>
                    <div class="text-lg mb-2">

                        <a href="https://wa.me/00966114501893" target="_blank" class="text-lg text-green-600">
                            <svg class="inline" height="25px" width="25px" version="1.1" id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 58 58" xml:space="preserve">
                                <g>
                                    <path style="fill:#2CB742;" d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5
                                        S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z" />
                                    <path style="fill:#FFFFFF;"
                                        d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42
                                        c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242
                                        c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169
                                        c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097
                                        c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z" />
                                </g>
                            </svg>
                            <span class="mr-5 inline"> رابط مباشر للواتس اب</span>

                        </a>
                    </div>
                    <div class="border-t border-gray-300 my-4"></div>
                    <div class="text-gray-600 text-center">
                        <p>ساعات العمل/</p>
                        <p>من السبت إلى الخميس من 8 ص حتى 9 م</p>
                    </div>
                </div>
            </template>

        </Modal>

        <Modal :isOpen="openLogOutModel" @close="openLogOutModel = false">
            <template v-slot:header>
                <div class="p-4">
                    <img :src="exclamationMark" alt="" srcset="">
                </div>
            </template>
            <template v-slot:body>
                <div>
                    <h2 class="text-xl text-center mb-4">هل تريد تسجيل الخروج ؟</h2>
                    <div class="border-t border-gray-300 my-4"></div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex flex-col justify-center w-full">

                    <div class="mb-5 w-full">
                        <button @click="logout();" class="bg-tazej-greenWithTransparency-primary   w-full ">نعم</button>
                    </div>
                    <div class="mb-5 w-full">

                        <button @click="openLogOutModel = false"
                            class="bg-tazej-green-primary text-white w-full ">لا</button>
                    </div>


                </div>
            </template>
        </Modal>

        <div class="w-full flex flex-col mt-20 items-center justify-center gap-14">
            <div class="flex flex-row-reverse items-center justify-between bg-white rounded-lg ">
                <!-- Left Section -->
                <div class="flex flex-col items-center justify-center mb-8 mr-36">
                    <!-- Notification Icon -->

                    <!-- Edit Icon -->
                    <div class="w-6 h-6 flex items-center justify-center mt-16" @click="openUpdateUserModel = true">
                        <img :src="editIconUrl" alt="" srcset="">
                    </div>
                </div>

                <!-- Center Section -->
                <div class="text-right">
                    <h2 class="text-gray-800 text-xl font-bold">{{ authStore?.userData?.name ?? "" }}</h2>

                    <div class="mt-4">
                        <img :src="phoneIconUrl" alt="" srcset="" class="inline-block">
                        <span class="text-gray-600 text-sm">
                            {{ authStore?.userData?.phone ?? "" }}
                        </span>
                    </div>
                </div>

                <!-- Right Section -->
                <div class="w-20 h-20 rounded-full flex items-center justify-center ml-4">
                    <img :src="profileImageUrl" class="w-full" alt="" srcset="">
                </div>
            </div>
            <div class="w-full flex flex-col items-center justify-center gap-10">
                <UserList :items="listItems" />
                <div class="bg-gray-100 p-4 rounded-2xl flex justify-evenly	 w-11/12	">
                    <div class="w-8	h-8">
                        <a href="https://play.google.com/store/apps/details?id=com.intixdwc.adc.customer"
                            target="_blank" class="block w-full	h-full">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                                class="w-full	h-full" viewBox="0 0 48 48">
                                <linearGradient id="AraffhWwwEqZfgFEBZFoqa_L1ws9zn2uD01_gr1" x1="18.102" x2="25.297"
                                    y1="3.244" y2="34.74" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stop-color="#35ab4a"></stop>
                                    <stop offset=".297" stop-color="#31a145"></stop>
                                    <stop offset=".798" stop-color="#288739"></stop>
                                    <stop offset="1" stop-color="#237a33"></stop>
                                </linearGradient>
                                <path fill="url(#AraffhWwwEqZfgFEBZFoqa_L1ws9zn2uD01_gr1)"
                                    d="M13.488,4.012C10.794,2.508,7.605,3.778,6.45,6.323L24.126,24l9.014-9.014L13.488,4.012z">
                                </path>
                                <linearGradient id="AraffhWwwEqZfgFEBZFoqb_L1ws9zn2uD01_gr2" x1="19.158" x2="21.194"
                                    y1="23.862" y2="66.931" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stop-color="#f14e5d"></stop>
                                    <stop offset=".499" stop-color="#ea3d4f"></stop>
                                    <stop offset="1" stop-color="#e12138"></stop>
                                </linearGradient>
                                <path fill="url(#AraffhWwwEqZfgFEBZFoqb_L1ws9zn2uD01_gr2)"
                                    d="M33.14,33.014L24.126,24L6.45,41.677 c1.156,2.546,4.345,3.815,7.038,2.312L33.14,33.014z">
                                </path>
                                <linearGradient id="AraffhWwwEqZfgFEBZFoqc_L1ws9zn2uD01_gr3" x1="32.943" x2="36.541"
                                    y1="14.899" y2="43.612" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stop-color="#ffd844"></stop>
                                    <stop offset=".519" stop-color="#ffc63f"></stop>
                                    <stop offset="1" stop-color="#ffb03a"></stop>
                                </linearGradient>
                                <path fill="url(#AraffhWwwEqZfgFEBZFoqc_L1ws9zn2uD01_gr3)"
                                    d="M41.419,28.393 c1.72-0.96,2.58-2.676,2.581-4.393c-0.001-1.717-0.861-3.434-2.581-4.393l-8.279-4.621L24.126,24l9.014,9.014L41.419,28.393z">
                                </path>
                                <linearGradient id="AraffhWwwEqZfgFEBZFoqd_L1ws9zn2uD01_gr4" x1="13.853" x2="15.572"
                                    y1="5.901" y2="42.811" gradientUnits="userSpaceOnUse">
                                    <stop offset=".003" stop-color="#0090e6"></stop>
                                    <stop offset="1" stop-color="#0065a0"></stop>
                                </linearGradient>
                                <path fill="url(#AraffhWwwEqZfgFEBZFoqd_L1ws9zn2uD01_gr4)"
                                    d="M6.45,6.323C6.168,6.948,6,7.652,6,8.408 v31.179c0,0.761,0.164,1.463,0.45,2.09l17.674-17.68L6.45,6.323z">
                                </path>
                            </svg>

                        </a>

                    </div>
                    <div class="w-8	h-8">
                        <a href="https://apps.apple.com/us/app/tazej/id1150041871" target="_blank"
                            class="block w-full	h-full">
                            <svg fill="#000000" viewBox="-52.01 0 560.035 560.035" x="0px" y="0px" width="100"
                                class="w-full	h-full" height="100" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655">
                                    </path>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>



                <div class="bg-gray-100 p-4 rounded-2xl flex justify-center w-11/12	">
                    <!-- TikTok Icon -->
                    <div
                        class="w-12 h-12 bg-tazej-lightGreen-primary rounded-full flex items-center justify-center text-white ml-4">
                        <a href="https://www.tiktok.com/@tazejfood" target="_blank">
                            <svg fill="#ffffff" width="24px" height="24px" viewBox="0 0 512 512" id="icons"
                                xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z">
                                    </path>
                                </g>
                            </svg>

                        </a>
                    </div>

                    <!-- YouTube Icon -->
                    <div
                        class="w-12 h-12 bg-tazej-lightGreen-primary rounded-full flex items-center justify-center text-white ml-4">
                        <a href="https://youtu.be/O9SdDu7uS-w?si=Uf4siZ1s10jeFHWa" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0,0,256,256" width="24px" height="24px" fill-rule="nonzero">
                                <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1"
                                    stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10"
                                    stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
                                    font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                                    <g transform="scale(10.66667,10.66667)">
                                        <path
                                            d="M21.582,6.186c-0.23,-0.86 -0.908,-1.538 -1.768,-1.768c-1.56,-0.418 -7.814,-0.418 -7.814,-0.418c0,0 -6.254,0 -7.814,0.418c-0.86,0.23 -1.538,0.908 -1.768,1.768c-0.418,1.56 -0.418,5.814 -0.418,5.814c0,0 0,4.254 0.418,5.814c0.23,0.86 0.908,1.538 1.768,1.768c1.56,0.418 7.814,0.418 7.814,0.418c0,0 6.254,0 7.814,-0.418c0.861,-0.23 1.538,-0.908 1.768,-1.768c0.418,-1.56 0.418,-5.814 0.418,-5.814c0,0 0,-4.254 -0.418,-5.814zM10,15.464v-6.928l6,3.464z">
                                        </path>
                                    </g>
                                </g>
                            </svg>

                        </a>
                    </div>
                    <!-- Instagram Icon -->
                    <div
                        class="w-12 h-12 bg-tazej-lightGreen-primary rounded-full flex items-center justify-center text-white ml-4">
                        <a href="https://www.instagram.com/tazejfood/profilecard/?igsh=MXV4dWdveWQ2ZHN6dw=="
                            target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0,0,256,256" width="24px" height="24px" fill-rule="nonzero">
                                <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1"
                                    stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10"
                                    stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
                                    font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                                    <g transform="scale(10.66667,10.66667)">
                                        <path
                                            d="M8,3c-2.757,0 -5,2.243 -5,5v8c0,2.757 2.243,5 5,5h8c2.757,0 5,-2.243 5,-5v-8c0,-2.757 -2.243,-5 -5,-5zM8,5h8c1.654,0 3,1.346 3,3v8c0,1.654 -1.346,3 -3,3h-8c-1.654,0 -3,-1.346 -3,-3v-8c0,-1.654 1.346,-3 3,-3zM17,6c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM12,7c-2.757,0 -5,2.243 -5,5c0,2.757 2.243,5 5,5c2.757,0 5,-2.243 5,-5c0,-2.757 -2.243,-5 -5,-5zM12,9c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <!-- X (Twitter) Icon -->
                    <div
                        class="w-12 h-12 bg-tazej-lightGreen-primary rounded-full flex items-center justify-center text-white ">
                        <a href="https://x.com/tazejfood" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0,0,256,256" width="24px" height="24px" fill-rule="nonzero">
                                <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1"
                                    stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10"
                                    stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
                                    font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                                    <g transform="scale(10.66667,10.66667)">
                                        <path
                                            d="M2.36719,3l7.0957,10.14063l-6.72266,7.85938h2.64063l5.26367,-6.16992l4.31641,6.16992h6.91016l-7.42187,-10.625l6.29102,-7.375h-2.59961l-4.86914,5.6875l-3.97266,-5.6875zM6.20703,5h2.04883l9.77734,14h-2.03125z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="flex flex-row-reverse">
                    <div class="mr-4">
                        <img :src="vatIconUrl" alt="" srcset="">
                    </div>
                    <div>
                        <div>302008150900003</div>
                        <div>رقم الإصدار 5.5.56</div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>
