<script setup>
import { useCartStore } from "@/stores/cart.store";
import { computed } from "vue";

const props = defineProps({
    productData: Object,
});
const productSearch = (id) => {
    return useCartStore().products.filter((product) => {
        return product.id == id;
    }).length;
};

const totalPrice = computed(() => {
    const quantity = productSearch(props.productData.id);
    const price =
        props.productData.discount_percent > 0
            ? parseFloat(discountedPrice.value)
            : props.productData.minimum_price * 1.15;
    return (price * quantity).toFixed(2);
});

const getDiscountedPrice = (price, discountPercent) => {
    if (typeof price !== "number" || price < 0) {
        console.error("خطأ: السعر غير صحيح");
        return 0;
    }
    if (
        typeof discountPercent !== "number" ||
        discountPercent < 0 ||
        discountPercent > 100
    ) {
        console.error("خطأ: نسبة الخصم غير صحيحة");
        return price;
    }

    if (discountPercent > 0) {
        const discount = discountPercent;
        const priceWithTax = price * 1.15;
        const discountWithTax = discount * 1.15;
        const finalPrice = priceWithTax - discountWithTax;
        return finalPrice.toFixed(2);
    }
    return (price * 1.15).toFixed(2);
};

const discountedPrice = computed(() => {
    const price = props.productData?.minimum_price ?? 0;
    const discountPercent = props.productData?.discount_percent ?? 0;
    return getDiscountedPrice(price, discountPercent);
});

const handleRemoveProduct = (productId) => {
    useCartStore().removeItem(productId);
};

const removeProductFromCart = (id) => {
    useCartStore().removeFromCart(id);
};

const addProductToCart = (product) => {
    useCartStore().addToCart(product);
};
</script>

<template>
    <div class="w-full h-24 relative bg-[#f4f4f4] my-4 flex items-center gap-4 rounded-lg p-2">
        <!-- الصورة -->
        <img :src="productData.image
            ? 'data:image/png;base64,' + productData.image
            : '/public/img/itemImage.png'
            " class="w-[72px] h-[72px] rounded-2xl object-cover" alt="صورة المنتج" />

        <!-- النصوص -->
        <div class="flex flex-col justify-between rounded-lg flex-grow">
            <!-- اسم المنتج -->
            <p class="text-sm font-bold my-4 text-right text-[#515151]">
                {{ productData.name }}
            </p>
            <!-- السعر -->
            <div class="flex justify-center items-center w-28 h-7 gap-2 p-2 bg-[#d1d1d1]">
                {{
                    productData.discount_percent > 0
                        ? (Number(discountedPrice) * Number(productSearch(productData.id))).toFixed(2) + " ريال "
                        : (Number(productData.minimum_price ?? 0) * 1.15 *
                            Number(productSearch(productData.id).toFixed(2))).toFixed(2) +
                        " ريال "
                }}
            </div>
            <!-- زر حذف المنتج -->
        </div>

        <!-- الأزرار -->

        <div>
            <div class="flex gap-2">
                <svg @click="addProductToCart(productData)" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" preserveAspectRatio="xMidYMid meet">
                    <rect width="24" height="24" rx="8" fill="#408E58"></rect>
                    <circle cx="12" cy="12" r="7.5" stroke="white"></circle>
                    <path
                        d="M11.4873 12.5129H8.06848C7.92319 12.5129 7.80142 12.4638 7.70319 12.3654C7.60484 12.2671 7.55566 12.1452 7.55566 11.9999C7.55566 11.8545 7.60484 11.7328 7.70319 11.6346C7.80142 11.5364 7.92319 11.4873 8.06848 11.4873H11.4873V8.06848C11.4873 7.92319 11.5365 7.80142 11.6348 7.70319C11.7332 7.60484 11.855 7.55566 12.0003 7.55566C12.1457 7.55566 12.2675 7.60484 12.3656 7.70319C12.4638 7.80142 12.5129 7.92319 12.5129 8.06848V11.4873H15.9317C16.077 11.4873 16.1988 11.5365 16.297 11.6348C16.3954 11.7332 16.4446 11.855 16.4446 12.0003C16.4446 12.1457 16.3954 12.2675 16.297 12.3656C16.1988 12.4638 16.077 12.5129 15.9317 12.5129H12.5129V15.9317C12.5129 16.077 12.4638 16.1988 12.3654 16.297C12.2671 16.3954 12.1452 16.4446 11.9999 16.4446C11.8545 16.4446 11.7328 16.3954 11.6346 16.297C11.5364 16.1988 11.4873 16.077 11.4873 15.9317V12.5129Z"
                        fill="white"></path>
                </svg>

                <span class="font-semibold">{{ productSearch(productData.id) }}</span>

                <svg @click="removeProductFromCart(productData.id)" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" preserveAspectRatio="xMidYMid meet">
                    <rect width="24" height="24" rx="8" fill="#408E58"></rect>
                    <circle cx="12" cy="12" r="7.5" stroke="white"></circle>
                    <path
                        d="M7.57692 13C7.41346 13 7.27647 12.9521 7.16596 12.8562C7.05532 12.7603 7 12.6415 7 12.4998C7 12.3581 7.05532 12.2393 7.16596 12.1437C7.27647 12.0479 7.41346 12 7.57692 12H16.4231C16.5865 12 16.7235 12.0479 16.834 12.1438C16.9447 12.2397 17 12.3585 17 12.5002C17 12.6419 16.9447 12.7607 16.834 12.8563C16.7235 12.9521 16.5865 13 16.4231 13H7.57692Z"
                        fill="white"></path>
                </svg>
            </div>

            <button @click="handleRemoveProduct(productData.id)" class="flex items-center my-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4" preserveAspectRatio="none">
                    <path
                        d="M4.66675 14C4.30008 14 3.98619 13.8694 3.72508 13.6083C3.46397 13.3472 3.33341 13.0333 3.33341 12.6667V4C3.14453 4 2.98619 3.93611 2.85841 3.80833C2.73064 3.68056 2.66675 3.52222 2.66675 3.33333C2.66675 3.14444 2.73064 2.98611 2.85841 2.85833C2.98619 2.73056 3.14453 2.66667 3.33341 2.66667H6.00008C6.00008 2.47778 6.06397 2.31944 6.19175 2.19167C6.31953 2.06389 6.47786 2 6.66675 2H9.33341C9.5223 2 9.68064 2.06389 9.80841 2.19167C9.93619 2.31944 10.0001 2.47778 10.0001 2.66667H12.6667C12.8556 2.66667 13.014 2.73056 13.1417 2.85833C13.2695 2.98611 13.3334 3.14444 13.3334 3.33333C13.3334 3.52222 13.2695 3.68056 13.1417 3.80833C13.014 3.93611 12.8556 4 12.6667 4V12.6667C12.6667 13.0333 12.5362 13.3472 12.2751 13.6083C12.014 13.8694 11.7001 14 11.3334 14H4.66675ZM11.3334 4H4.66675V12.6667H11.3334V4ZM6.66675 11.3333C6.85564 11.3333 7.01397 11.2694 7.14175 11.1417C7.26953 11.0139 7.33341 10.8556 7.33341 10.6667V6C7.33341 5.81111 7.26953 5.65278 7.14175 5.525C7.01397 5.39722 6.85564 5.33333 6.66675 5.33333C6.47786 5.33333 6.31953 5.39722 6.19175 5.525C6.06397 5.65278 6.00008 5.81111 6.00008 6V10.6667C6.00008 10.8556 6.06397 11.0139 6.19175 11.1417C6.31953 11.2694 6.47786 11.3333 6.66675 11.3333ZM9.33341 11.3333C9.5223 11.3333 9.68064 11.2694 9.80841 11.1417C9.93619 11.0139 10.0001 10.8556 10.0001 10.6667V6C10.0001 5.81111 9.93619 5.65278 9.80841 5.525C9.68064 5.39722 9.5223 5.33333 9.33341 5.33333C9.14453 5.33333 8.98619 5.39722 8.85841 5.525C8.73064 5.65278 8.66675 5.81111 8.66675 6V10.6667C8.66675 10.8556 8.73064 11.0139 8.85841 11.1417C8.98619 11.2694 9.14453 11.3333 9.33341 11.3333Z"
                        fill="#9C9C9C"></path>
                </svg>

                <p class="text-[10px] font-semibold text-[#9c9c9c]">حذف المنتج</p>
            </button>
        </div>
    </div>

    <!--
   <div class="w-full select-none relative flex justify-between items-center flex-wrap bg-white p-2 sm:p-4">
        <div class="flex">
            <div class="w-36 h-36 flex-shrink-0 relative  border rounded-lg">
                <img class="w-full" :src="productData.imageUrl" alt="product image">
            </div>
            <div class="w-full mx-6">
                <h2 class="font-semibold text-xl">{{ productData.title }}</h2>
                <span class="text-tazej-green-light font-semibold text-xl">{{ $filters.currencySAR(productData.price)
                    }}</span>
                <span class="text-sm text-tazej-gray-400">ريال</span>
            </div>
        </div>

        <div class="self-start mt-4 sm:mt-0">
            <div
                class="text-black bg-white text-center w-full rounded border border-tazej-gray-200 flex justify-center p-2 gap-7">
                <button @click="addProductToCart(productData)">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                </button>
                <span class="font-semibold">{{ productSearch(productData.id) }}</span>
                <button @click="removeProductFromCart(productData.id)">
                    <font-awesome-icon :icon="['fas', 'minus']" />
                </button>
            </div>
        </div>
    </div>


-->
</template>
