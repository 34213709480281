<script setup>
import { ref, onMounted, computed } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { userService } from '@/service';
import { useAuthStore } from '@/stores/auth.store';
import { useRouter } from "vue-router";

const router = useRouter();
const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
const props = defineProps({
    initialCenter: {
        type: Object,
        default: () => ({ lat: 24.7136, lng: 46.6753 }) // Default to Riyadh coordinates
    }
});


const mapDiv = ref(null);
const searchInput = ref(null);
const addressDetails = ref({
    street: '',
    city: '',
    state: '',
    country: '',
    postalCode: ''
});
let map = null;
let marker = null;
let geocoder = null;
let searchBox = null;
let allPolygon = [];
const locationResult = {
    lat: 0,
    lng: 0
};

const coordinates = computed(() => {
    const cities = JSON.parse(localStorage.getItem('cities'));
    return cities.map(city => JSON.parse(city.area_boarder).polygon);
});

const loader = new Loader({
    apiKey: apiKey,
    version: 'weekly',
    libraries: ['places', 'geometry']
});
let google = null;

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

onMounted(async () => {
    try {
        google = await loader.load();
        geocoder = new google.maps.Geocoder();

        // Initialize map
        map = new google.maps.Map(mapDiv.value, {
            center: props.initialCenter,
            zoom: 15,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
        });

        // Define the Saudi Arabia polygon
        for (let i = 0; i < coordinates.value.length; i++) {
            const coordValue = coordinates.value[i];

            allPolygon.push(new google.maps.Polygon({
                paths: coordValue.map(coord => ({ lng: coord[1], lat: coord[0] })),
                strokeColor: '#000',
                strokeOpacity: 1,    // visible outline
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0,

            }));
            allPolygon[i].setMap(map);

        }

        // Initialize marker with new SVG icon
        marker = new google.maps.Marker({
            position: props.initialCenter,
            map: map,
            draggable: false, // Make the marker non-draggable
            icon: {
                url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><g transform="translate(0, -1)"><path d="M12 23s8-9.3 8-14A8 8 0 1 0 4 9c0 4.7 8 14 8 14z" fill="%23006D42"/><circle cx="12" cy="9" r="3" fill="%23FFFFFF"/></g></svg>',
                scaledSize: new google.maps.Size(50, 50), // Adjust the size as needed
            }
        });
        let center = null;
        // Update marker position when the map center changes
        google.maps.event.addListener(map, 'center_changed', () => {
            center = map.getCenter();
            marker.setPosition(center);
        });


        google.maps.event.addListener(map, 'center_changed', debounce(() => {
            updateAddressDetails(center);
        }, 500));

        // Initialize search box
        searchBox = new google.maps.places.SearchBox(searchInput.value);
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(searchInput.value);

        // Handle search box events
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            if (places.length === 0) return;

            const place = places[0];
            if (!place.geometry || !place.geometry.location) return;

            map.setCenter(place.geometry.location);
        });

    } catch (error) {
        console.error('Error loading Google Maps:', error);
    }
});

async function updateAddressDetails(location) {
    if (!geocoder) return;

    try {
        const response = await geocoder.geocode({ location });
        if (response.results[0]) {
            const result = response.results[0];

            // Extract address components
            const addressComponents = {};
            result.address_components.forEach(component => {
                component.types.forEach(type => {
                    addressComponents[type] = component.long_name;
                });
            });

            // Update address details
            addressDetails.value = {
                street: `${addressComponents.street_number || ''} ${addressComponents.route || ''}`.trim(),
                city: addressComponents.locality || addressComponents.administrative_area_level_2 || '',
                state: addressComponents.administrative_area_level_1 || '',
                country: addressComponents.country || '',
                postalCode: addressComponents.postal_code || '',
                formattedAddress: result.formatted_address
            };

            locationResult.lat = location.lat();
            locationResult.lng = location.lng();


        }
    } catch (error) {
        console.error('Geocoding error:', error);
    }
}

async function selectAddress() {
    let isWithinAnyPolygon = [];

    allPolygon.forEach(polygon => {
        isWithinAnyPolygon.push(google.maps.geometry.poly.containsLocation(locationResult, polygon));
    });

    if (isWithinAnyPolygon.some(e => e === true)) {
        const userData = useAuthStore().userData ?? JSON.parse(localStorage.getItem('userData'));

        // Emit the location and address details
        const newAddress = await userService.addNewUserAddress({
            user_id: userData.id,
            name: userData.name,
            phone: userData.phone,
            street: addressDetails.value.street,
            city: addressDetails.value.city,
            address_type: "home",
            is_default_add: false,
            lat: locationResult.lat,
            lng: locationResult.lng
        });

        if (!!newAddress?.data?.result?.id) {
            router.push('/addresses');
        }
    } else {
        alert('Selected location is outside the allowed areas.');
    }
}
</script>

<template>
    <div class="relative w-full">
        <input ref="searchInput" type="text" placeholder="بحث عن عنوان"
            class="w-[80%] p-2 m-2 border rounded-lg shadow-sm absolute top-2 left-1/2  z-10">
        <div ref="mapDiv" class="w-full h-[800px] rounded-lg"></div>

        <!-- Address Details Panel -->
        <div v-if="addressDetails.formattedAddress" class="absolute bottom-4 left-1/2 transform -translate-x-1/2
         w-[300px] bg-white rounded-lg shadow-md p-3
         text-center z-10 text-gray-800">
            <!-- Display the address in one or two lines -->
            <p class="text-sm mb-2">
                {{ addressDetails.formattedAddress }}
            </p>

            <!-- “تحديد العنوان” with a green check mark -->
            <div class="text-green-600 text-sm font-medium" @click="selectAddress">
                تحديد العنوان &#10003;
            </div>
        </div>
    </div>
</template>

<style scoped>
.gmnoprint {
    direction: rtl;
}
</style>
