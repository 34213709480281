<template>
    <div class="flex flex-col items-center">
        <!-- Input boxes -->
        <div class="flex gap-x-4">
            <input type="number" max="9" min="0" v-model="inputValues[3]"
                oninput="if(this.value.length > 1) this.value = this.value.slice(0, 1);" @input="handleInput($event, 3)"
                class="w-10 h-10 text-center border-b-2 text-xl focus:outline-none focus:border-blue-500" ref="otp3" />
            <input type="number" max="9" min="0" v-model="inputValues[2]"
                oninput="if(this.value.length > 1) this.value = this.value.slice(0, 1);" @input="handleInput($event, 2)"
                class="w-10 h-10 text-center border-b-2 text-xl focus:outline-none focus:border-blue-500" ref="otp2" />
            <input type="number" max="9" min="0" v-model="inputValues[1]"
                oninput="if(this.value.length > 1) this.value = this.value.slice(0, 1);" @input="handleInput($event, 1)"
                class="w-10 h-10 text-center border-b-2 text-xl focus:outline-none focus:border-blue-500" ref="otp1" />
            <input type="number" max="9" min="0" v-model="inputValues[0]"
                oninput="if(this.value.length > 1) this.value = this.value.slice(0, 1);" @input="handleInput($event, 0)"
                class="w-10 h-10 text-center border-b-2 text-xl focus:outline-none focus:border-blue-500" ref="otp0" />
        </div>

        <!-- Timer -->
        <div class="mt-4 text-lg font-medium text-gray-700">{{ formattedTime }}</div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

// Reactive array to hold input values
const inputValues = ref(["", "", "", ""]);
const timer = ref(59); // Countdown timer in seconds
const otp0 = ref(null);
const otp1 = ref(null);
const otp2 = ref(null);
const otp3 = ref(null);
const emit = defineEmits(['input-combined']);
// Computed property to format the timer (mm:ss)
const formattedTime = computed(() => {
    const minutes = Math.floor(timer.value / 60);
    const seconds = timer.value % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
});

// Handle input change and move to the next field
const handleInput = (event, index) => {
    const value = event.target.value;
    inputValues.value[index] = value;

    if (inputValues.value[index] > 1) {
        inputValues.value[index] = inputValues.value[index].slice(0, 1);
    }

    if (inputValues.value[index].length === 1 && index < 3) {
        // Move focus to the next input
        if (index === 0) otp1.value.focus();
        if (index === 1) otp2.value.focus();
        if (index === 2) otp3.value.focus();
    }


    if (inputValues.value[index].length === 0 && index <= 3) {
        // Move Back focus to the next input
        if (index === 3) otp2.value.focus();
        if (index === 2) otp1.value.focus();
        if (index === 1) otp0.value.focus();
    }


    // Emit the combined input value as one string

    const combinedValue = inputValues.value.join("");
    // Emit event to parent component
    if (combinedValue.length === 4) {
        emit("input-combined", combinedValue);

    }
};

// Start the countdown timer
const startTimer = () => {
    const interval = setInterval(() => {
        if (timer.value > 0) {
            timer.value--;
        } else {
            clearInterval(interval);

        }
    }, 1000);
};

// Lifecycle hooks
onMounted(() => {
    startTimer();
});

onBeforeUnmount(() => {
    timer.value = 0;
});
</script>
<style scoped>
/* Hide arrows in number input for all browsers */
input[type="number"] {
    -moz-appearance: textfield;
    /* For Firefox */
    appearance: textfield;
    /* For modern browsers */
}

/* Hide the spinner in WebKit browsers (Chrome, Safari, Edge) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>