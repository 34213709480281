
export const getDiscountedPrice = (price, discountPercent) => {
    if (typeof price !== "number" || price < 0) {
      console.error("خطأ: السعر غير صحيح");
      return 0;
    }
    if (
      typeof discountPercent !== "number" ||
      discountPercent < 0 ||
      discountPercent > 100
    ) {
      console.error("خطأ: نسبة الخصم غير صحيحة");
      return price;
    }
  
    if (discountPercent > 0) {
      const discount = discountPercent;
      const priceWithTax = price * 1.15;
      const discountWithTax = discount * 1.15;
      const finalPrice = priceWithTax - discountWithTax;
      return finalPrice.toFixed(2);
    }
    return (price * 1.15).toFixed(2);
  };