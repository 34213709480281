<template>
    <div class="relative " ref="dropdownRef">
        <!-- Default Select Box (Closed State) -->
        <div @click="toggleDropdown"
            class="w-full p-2 border rounded-md flex justify-between items-center cursor-pointer">
            <span class="text-right">{{ selectedOption ? selectedOption.label : placeholder }}</span>
        </div>
        {{ selectOption }}

        <!-- Dropdown with Search (Opened State) -->
        <div v-if="showDropdown"
            class="absolute z-10 w-full mt-1 bg-blue-50 border rounded-md shadow-lg max-h-60 overflow-auto p-2">
            <input type="text" v-model="searchQuery" @input="filterOptions" :placeholder="placeholder"
                class="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 mb-2" />
            <div v-for="option in filteredOptions" :key="option.value" @click="selectOptionFunction(option)"
                class="p-2 hover:bg-gray-200 cursor-pointer text-right">
                {{ option.label }}
            </div>
            <div v-if="filteredOptions.length === 0" class="p-2 text-gray-500 text-right">
                لا توجد نتائج
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
const props = defineProps({
    options: {
        type: Array,
        required: true
    },
    placeholder: {
        type: String,
        default: 'بحث...'
    },
    modelValue: {
        type: [String, Number, Object],
        default: null
    }
});


const emit = defineEmits(['update:modelValue']);

const searchQuery = ref('');
const showDropdown = ref(false);
const selectedOption = ref(null);
const filteredOptions = ref([...props.options]);
const dropdownRef = ref(null);

function filterOptions() {
    filteredOptions.value = props.options.filter(option =>
        option.label.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
}

function selectOptionFunction(option) {
    selectedOption.value = option;
    emit('update:modelValue', option.value);
    searchQuery.value = option.label;
    showDropdown.value = false;
}

function toggleDropdown(event) {
    event.stopPropagation(); // Prevents click from immediately closing the dropdown
    showDropdown.value = !showDropdown.value;
}

function handleClickOutside(event) {
    if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
        showDropdown.value = false;
    }
}

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>
