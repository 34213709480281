import { initializeApp } from 'firebase/app'
import { getFirestore, collection } from 'firebase/firestore'

export const firebaseApp = initializeApp( {
    apiKey: "AIzaSyA25MPVIFsl1l3UczzZXGmfq-cGn137kfw",
    authDomain: "tazej-ddf10.firebaseapp.com",
    databaseURL: "https://tazej-ddf10-default-rtdb.firebaseio.com",
    projectId: "tazej-ddf10",
    storageBucket: "tazej-ddf10.appspot.com",
    messagingSenderId: "11017125420",
    appId: "1:11017125420:web:48bb891756466e3ce5db89",
    measurementId: "G-M4KL3HYW88"
})

// used for the firestore refs
const db = getFirestore(firebaseApp)

// here we can export reusable database references
export const todosRef = collection(db, 'todos')
