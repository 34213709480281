<template>
    <div class="ml-8 my-6">
        <input type="text" v-model="stringValue" @keyup="handleKeyup" :class="{ 'valid': isValid, 'invalid': !isValid }"
            class="my-2" :placeholder="placeholder" />
        <div v-if="!isValid" :class="{ 'valid': isValid, 'invalid': !isValid }" class="error-message mb-4">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue';

const emit = defineEmits(['validation']);
const stringValue = ref("");

const handleKeyup = () => {
    emit('input', stringValue.value);
};

const props = defineProps({
    placeholder: {
        type: String,
        default: 'رقم الجوال'
    },
    errorMessage: {
        type: String,
        default: 'something went wrong'
    },
    value: {
        type: String,
        default: ''
    }
});
const isValid = ref(true);
onMounted(() => {
    if (props.value.length > 0) {
        stringValue.value = props.value;
    }
});

</script>

<style scoped>
input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border: solid 1px #515151;
    border-radius: 8px;
}

input.valid,
input:focus.valid {
    border-color: green;
    color: green;
}

input.invalid,
input:focus.invalid {
    border-color: red;
    color: red;
}

input div.invalid {
    color: red;
}
</style>