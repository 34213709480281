<script setup>
import { ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import Logo from '@/components/Logo.vue';
import PhoneInput from '@/components/PhoneInput.vue';
import GreenButton from '@/components/GreenButton.vue';
import { useAuthStore } from '@/stores/auth.store';
import { authService } from '@/service';
const authStore = useAuthStore();
const isButtonDisabled = ref(true);
const router = useRouter();
const phone = ref('');
async function handleButtonClick() {

    const user = await authService.signIn({
        phones: phone.value,
    })

    if (user.data?.result?.status === "already exists") {
        authStore.phone = phone.value;
        authStore.sendOTP();
        authStore.setUserData({
            id: user.data.result.id,
            name: user.data.result.name,
            phone: user.data.result.phone,
            lang: user.data.result.lang,
            sale_order_count: user.data.result.sale_order_count
        });
        router.push('/verifyPage');
    } else {
        alert("User not found");
    }

}

function handleValidationStatus(status) {
    isButtonDisabled.value = !status;
}

function handleInput(value) {
    phone.value = value;
}
</script>

<template>
    <AppLayout :nav-bar-status="false" :back-home-status="true" title="تسجيل الدخول">
        <div class="w-full mt-20 mb-20">
            <Logo class="flex items-center justify-center" />
        </div>
        <div class="mr-4 text-sm">
            <div>
                قم بإدخال رقم الجوال المسجل لدينا لإتمام عملية تسجيل الدخول
            </div>
            <PhoneInput @validation="handleValidationStatus" @input="handleInput" />
            <div class="ml-8">
                <GreenButton :button-classes="'mr-0 ml-4'" v-model="isButtonDisabled" @click="handleButtonClick">
                    تسجيل الدخول
                </GreenButton>
            </div>
            <div class="text-[#F48C29] text-center text-lg my-5 rtl">
                <router-link to="/privacy-policy">
                    - سياسة الخصوصية -
                </router-link>
            </div>
            <div class="w-full mt-20 mb-20">
                <Logo class="flex items-center justify-center" />
                <div class="text-center mt-4">
                    <p class="text-sm text-[#408E58]">
                        لا يوجد لديك حساب مسجل, سجل الآن
                    </p>
                    <router-link to="/registerPage" class="text-lg text-gray-800 mt-2">
                        حساب جديد
                    </router-link>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<style scoped></style>
