import "./assets/main.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useProductsStore } from "./stores/products.store";
import { VueFire } from "vuefire";
import { firebaseApp } from "./plugins/firebase";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


const app = createApp(App);

app.use(VueFire, {
    firebaseApp,
});
app.use(createPinia());
app.use(VCalendar, {
    locales: {
        'ar': {
            firstDayOfWeek: 6, // Saturday is first day of week in Arabic calendar
            masks: {
                L: 'YYYY/MM/DD',
                title: 'MMMM YYYY',
            },
            dayNames: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
            dayNamesShort: ['أحد', 'اثن', 'ثلا', 'أرب', 'خمي', 'جمع', 'سبت'],
            monthNames: [
                'يناير',
                'فبراير',
                'مارس',
                'أبريل',
                'مايو',
                'يونيو',
                'يوليو',
                'أغسطس',
                'سبتمبر',
                'أكتوبر',
                'نوفمبر',
                'ديسمبر'
            ],
            monthNamesShort: ['ينا', 'فبر', 'مار', 'أبر', 'ماي', 'يون', 'يول', 'أغس', 'سبت', 'أكت', 'نوف', 'ديس'],
        }
    }
});
app.use(router);
app.use(Toast);

app.config.globalProperties.$filters = {
    currencySAR(value) {
        let val = value / 1;
        3;
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
};

app.component("font-awesome-icon", FontAwesomeIcon);


app.mount("#app");

// useProductsStore().fetchVersionAndUpdateProducts();
