<script setup>
import { ref, computed } from "vue";
import AppLayout from "@/layouts/AppLayout.vue";
import OrderItem from "@/components/OrderItem.vue";
import EmptyOrder from "@/components/EmptyOrder.vue";
import orderService from "@/service/orderService";
import { useAuthStore } from "@/stores/auth.store";
import Spinner from "@/components/Spinner.vue";

const authStore = useAuthStore();

const pastOrders = ref([]);
const currentOrders = ref([]);

async function getOrders() {
    const ordersIds = await orderService.getPastOrders(authStore.userData.id);

    if (ordersIds?.data?.result.length > 0) {
        for (let i = 0; i < ordersIds?.data?.result.length; i++) {
            const orderId = ordersIds?.data?.result[i];
            const order = await orderService.getOrderDetails(orderId);
            if (order?.data?.result) {
                order.data.result.qty = order.data.result.order_lines.reduce(
                    (total, ol) => {
                        return total + (ol.price < 0 ? 1 : 0);
                    },
                    0
                );
                order.data.result.timeRange = order.data.result.period.name;
                pastOrders.value.push(order?.data?.result);
            }
        }
    }
}
async function getCurrentOrders() {
    const response = await orderService.getCurrentOrders(authStore.userData.id);

    if (response?.data?.result?.length > 0) {
        for (let i = 0; i < response.data.result.length; i++) {
            const orderId = response.data.result[i];
            const order = await orderService.getOrderDetails(orderId);
            if (order?.data?.result) {
                order.data.result.qty = order.data.result.order_lines.reduce(
                    (total, ol) => {
                        return total + (ol.price < 0 ? 1 : 0);
                    },
                    0
                );
                order.data.result.timeRange = order.data.result.period.name;
                currentOrders.value.push(order.data.result);
            }
        }
        console.log(currentOrders.value);
    }
}

const moveOrderToPrevious = (order) => {
    // التحقق من وجود الطلب في قائمة الطلبات الحالية
    const index = currentOrders.value.findIndex((o) => o.id === order.id);
    if (index !== -1) {
        // إزالة الطلب من قائمة تحت التنفيذ
        currentOrders.value.splice(index, 1);
    }

    // إضافة الطلب إلى قائمة الطلبات السابقة
    pastOrders.value.unshift(order);
};

const activeTab = ref("active");

const filteredOrders = computed(() => {
    return orders.filter((order) => order.status === activeTab.value);
});
const isLoading = ref(false);

const refreshOrders = async () => {


    isLoading.value = true;

    try {
        await getOrders();
        await getCurrentOrders();
    } catch (error) {
        console.error("Error refreshing orders:", error);
    } finally {
        isLoading.value = false;
    }
};


onMounted(() => {
    setTimeout(() => {
        getOrders();
        getCurrentOrders();
        refreshOrders();
    });
});
</script>

<template>
    <AppLayout>
        <div class="flex w-full h-20 text-white text-xl px-10 justify-between items-center bg-tazej-lightGreen-primary">
            <div class="mr-6 font-cairo">الطلبات</div>
            <div>
                <Spinner :isLoading="isLoading" v-if="isLoading" />
                <svg v-else @click="refreshOrders" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                    height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="cursor-pointer text-white hover:text-gray-200">
                    <path d="M23 4v6h-6" />
                    <path d="M1 20v-6h6" />
                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
                </svg>
            </div>
        </div>
        <div class="w-full h-[40px] bg-[#408e58] relative overflow-hidden z-[15] mt-[8px]">
            <div class="flex max-w-[342px] h-[26px] justify-center items-center gap-4 relative z-[17] mt-[7px] mx-auto">
                <span @click="activeTab = 'active'" :class="[
                    activeTab === 'active' ? 'text-[#fff] active-tab ' : 'text-[#ccc]',
                    'flex w-[121px] h-[26px] ml-10 justify-end items-start shrink-0 font-cairo text-[14px] font-bold leading-[26px] relative text-right whitespace-nowrap z-[16] cursor-pointer',
                ]">
                    الطلبات تحت النتفيذ
                </span>
                <span @click="activeTab = 'previous'" :class="[
                    activeTab === 'previous'
                        ? 'text-[#fff] active-tab '
                        : 'text-[#ccc]',
                    'flex w-[90px] h-[26px] justify-end items-start shrink-0 font-cairo text-[14px] font-normal leading-[26px] relative text-right whitespace-nowrap z-[17] cursor-pointer',
                ]">
                    الطلبات السابقة
                </span>
            </div>
        </div>

        <div v-if="activeTab === 'active'" class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
            <OrderItem v-for="currentOrder in currentOrders" :key="currentOrder.id" :order-data="currentOrder"
                :orderCancelled="moveOrderToPrevious" />
        </div>

        <div v-else class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
            <OrderItem v-for="pastOrder in pastOrders" :key="pastOrder.id" :order-data="pastOrder" />
        </div>
        <div v-if="
            (activeTab === 'active' && currentOrders.length === 0) ||
            (activeTab === 'active' && pastOrders.length === 0)
        ">
            <EmptyOrder />
        </div>
    </AppLayout>
</template>
<style scoped>
.active-tab {
    position: relative;
}

.active-tab::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 10px;
    height: 1px;
    background-color: #f48c29;
    width: 100%;
}
</style>
