<template>
    <button :class="buttonClasses" :disabled="isDisabled" @click="onClick"
        class="text-white p-2 text-center rounded-md bg-tazej-lightGreen-primary w-full mx-4 text-base font-bold">
        <slot></slot>
    </button>
</template>

<script setup>
import { computed , defineProps, defineEmits } from 'vue';

const props = defineProps({
    buttonClasses: {
        type: String,
        default: ''
    },
    modelValue: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['onClick', 'update:modelValue']);

const isDisabled = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

function onClick(event) {
    emit('onClick', event);
}


</script>
