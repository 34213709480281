import { openDB } from 'idb';

class IndexedDbService {
    constructor(dbName, version = 1) {
        this.dbName = dbName;
        this.version = version;
        this.db = null;
    }
    static async getVersion(dbName) {
        // Open the database to get the current version
        const tempDb = await openDB(dbName);
        const currentVersion = tempDb.version;
        tempDb.close();
        return currentVersion;
    }
    async openConnection() {
        this.db = await openDB(this.dbName, this.version, {
            upgrade(db) {
                [
                    { name: 'categories', keyPath: 'id', autoIncrement: false },
                ].forEach(store => {
                    if (!db.objectStoreNames.contains(store.name)) {
                        const objectStore = db.createObjectStore(store.name, {
                            keyPath: store.keyPath || 'id',
                            autoIncrement: store.autoIncrement || true
                        });
                        // // Set the fixed id value to 0
                    }
                });
            },
        });
        return this.db;
    }

    async add(storeName, data) {
        return await this.db.add(storeName, data);
    }

    async get(storeName, id) {
        return await this.db.get(storeName, id);
    }

    async getAll(storeName) {
        return await this.db.getAll(storeName);
    }

    async update(storeName, data) {
        return await this.db.put(storeName, data);
    }

    async delete(storeName, id) {
        return await this.db.delete(storeName, id);
    }

    async clear(storeName) {
        return await this.db.clear(storeName);
    }
}

export default IndexedDbService;