<script setup>
import AppLayout from '@/layouts/AppLayout.vue';
import Logo from '@/components/Logo.vue';
import OtpInput from '@/components/OtpInput.vue';
import { ref } from "vue";
import GreenButton from '@/components/GreenButton.vue';
import { useAuthStore } from '@/stores/auth.store';
import { authService } from '@/service';
const authStore = useAuthStore();
// Reactive variable to hold the combined input value
const router = useRouter();
const combinedInput = ref("");
const isButtonDisabled = ref(false);
// Handle the event emitted by the VerificationCodeInput component
const handleCombinedInput = (value) => {
    combinedInput.value = value;
    if (authStore.otp === Number(combinedInput.value)) {
        const token = authService.generateToken();
        authStore.setToken(token);
    } else {
        authStore.emptyUserData();
        alert("Invalid OTP");
    }
    router.push('/');
};

function reSendOtp() {
    authStore.sendOTP();
}
</script>

<template>
    <AppLayout :nav-bar-status="false" :back-home-status="true" title="التحقق من الجوال">
        <div class="w-full mt-20 mb-20">
            <Logo class="flex items-center justify-center" />
        </div>
        <div class="mx-4 text-sm text-center">
            <div>فضلاً أدخل رمز التحقق المرسل إليك للهاتف رقم {{ authStore.userData.phone }}</div>
        </div>
        <div class="mx-4 text-sm text-center">
            <OtpInput @input-combined="handleCombinedInput" />
        </div>
        <div class="m-4 text-sm text-center ">
            <GreenButton :button-classes="'mr-0 ml-4'" v-model="isButtonDisabled" @onClick="reSendOtp">
                أعد الإرسال
            </GreenButton>
        </div>
    </AppLayout>
</template>
