import { defineStore } from 'pinia'
import router from '@/router'
import { authService } from '@/service';



export const useAuthStore = defineStore({
    id: 'auth',


    state: () => ({
        user: JSON.parse(localStorage.getItem('user')),
        token: null,
        username: localStorage.getItem('username'),
        returnUrl: null,
        otp: null,
        phone: null,
        userData: JSON.parse(localStorage.getItem('userData')),
    }),

    actions: {
        async sendOTP() {
            this.otp = authService.generateOtp();
            return await authService.sendOtp(this.phone, this.otp);
        },

        setOtp(otpValue) {
            this.otp = otpValue; // Save OTP
        },
        setUserData(userData) {
            localStorage.setItem('userData', JSON.stringify(userData));
            this.userData = {
                id: userData.id,
                name: userData.name,
                phone: userData.phone,
                lang: userData.lang,
                sale_order_count: userData.sale_order_count
            };
        },
        emptyUserData() {
            this.userData = {};
        },
        setToken(token) {
            this.token = token;
            localStorage.setItem('token', JSON.stringify(token));
        },

        logout() {
            this.user = null;
            this.token = null;
            this.username = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('username');

            router.push('/');
        },






    }
});
