<script setup>
import { useCartStore } from "@/stores/cart.store";
import { useProductsStore } from "@/stores/products.store";
import { useRouter } from "vue-router";

const router = useRouter();
const productStore = useProductsStore();
const cartStore = useCartStore();
const showDropdown = ref(false);
const selectedArea = ref({
    name: "منطقة الرياض",
    id: 1
});
const deliveryAreas = ref([{
    name: "منطقة الرياض",
    id: 1
}, {
    name: "منطقة الشرقية",
    id: 2
}]);

// دالة لتبديل حالة المودال
const toggleDropdown = () => {
    showDropdown.value = !showDropdown.value;
};

function getArias() {
    for (let index = 0; index < productStore.cities.length; index++) {
        const city = productStore.cities[index];
        deliveryAreas.value[index] = city;
    }
}

watch(() => productStore.cities, () => {
    getArias()
});

// تحديد المنطقة 
const selectArea = (area) => {
    const oldArea = selectedArea.value;
    selectedArea.value = area;


    if (oldArea.id === area.id) {
        showDropdown.value = false;
        return;
    }


    productStore.selectedAria = productStore.cities.filter(city => city.id === area.id)[0];

    showDropdown.value = false;
    cartStore.clearCart();
    localStorage.setItem("selectedAria", JSON.stringify(productStore.selectedAria))
    productStore.fetchVersionAndUpdateProducts()
    router.push('/');
};

const onSearch = (searchValue) => {
    productStore.setSearchQuery(searchValue.target.value); // تحديث النص المدخل في المخزن
};
</script>

<template>
    <nav class="bg-tazej-green-primary flex items-center justify-between p-4 w-full sticky top-0 z-20">
        <div class="max-w-11">
            <img src="../../public/img/logo-white.png" alt="tazej logo" class="w-12" />
        </div>

        <div class="flex items-center mx-2 border rounded-full max-w-48 min-w-40 h-8 justify-center">
            <div class="px-4 items-center flex justify-center">
                <font-awesome-icon class="text-white w-5 h-5" :icon="['fas', 'search']" />
            </div>
            <input @input="onSearch($event)" type="text" id="searchInput" placeholder="ابحث عن منتج..."
                class="border-none w-full h-full bg-white rounded-r-none rounded-full outline-none pr-4" />
        </div>

        <div class="relative">
            <!-- عنوان التوصيل -->
            <div class="text-base mx-4 mb-1 text-white mb-1" style="white-space: nowrap" @click="toggleDropdown">
                عنوان التوصيل
            </div>

            <!-- خلفية مظللة للشاشة عند فتح المودال -->
            <div v-if="showDropdown" class="fixed inset-0 bg-black opacity-50 z-40" @click="toggleDropdown"></div>

            <!-- المودال (في نفس مكان القائمة المنسدلة) -->
            <div v-if="showDropdown"
                class="absolute top-full mt-2 bg-white shadow-lg border border-gray-300 rounded-md w-full z-50">
                <div v-for="(area) in deliveryAreas" @click="selectArea(area)"
                    class="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    <!-- أيقونة التحقق -->
                    <svg v-if="area.name === selectedArea.name" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                        fill="none" stroke="currentColor" class="w-6 h-6">
                        <circle cx="12" cy="12" r="10" fill="#FFA500" stroke="none" />
                        <path d="M8 12l2 2 4-4" stroke="#fff" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <!-- اسم المنطقة -->
                    <span :class="{ 'font-semibold text-orange-500': area.name === selectedArea.name }"
                        class="flex-1 text-xs text-center">
                        {{ area.name }}
                    </span>
                </div>
            </div>
        </div>
    </nav>
</template>
