<script setup>
import { ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import Logo from '@/components/Logo.vue';
import PhoneInput from '@/components/PhoneInput.vue';
import GreenButton from '@/components/GreenButton.vue';
import StringInput from '@/components/StringInput.vue';
import { authService } from '@/service';
import { useRouter } from "vue-router";

const router = useRouter();
const isButtonDisabled = ref(true);
const phoneNumber = ref("");
const fullName = ref("");
const handleButtonClick = async () => {
    const createUser = await authService.signUp({
        phone: phoneNumber.value,
        fullName: fullName.value
    });
    console.log(createUser);

    if (createUser?.data?.error) {
        alert(createUser.data.error);
    } else {
        router.push("/login");

    }

};
function getPhoneNumber(phone) {
    phoneNumber.value = phone;
}

function getFullName(name) {
    fullName.value = name;
}

const handleValidationStatus = (status) => {
    isButtonDisabled.value = !status;
};
</script>

<template>
    <AppLayout :nav-bar-status="false" :back-home-status="true" title="حساب جديد">
        <div class="w-full mt-20 mb-20">
            <Logo class="flex items-center justify-center" />
        </div>
        <div class="mr-4 text-sm">
            <div>قم بإدخالال البيانات لإتمام عملية إنشاء حساب جدبد</div>
            <PhoneInput @validation="handleValidationStatus" @input="getPhoneNumber" />
            <PhoneInput @validation="handleValidationStatus" placeholder="رقم الدعوة (اختياري)" />
            <StringInput @validation="handleValidationStatus" placeholder="الأسم بالكامل" @input="getFullName" />
            <div class="ml-8">
                <GreenButton :button-classes="'mr-0 ml-4'" v-model="isButtonDisabled" @click="handleButtonClick">
                    تسجيل الدخول
                </GreenButton>
            </div>
            <div class="text-[#F48C29] text-center text-lg my-5 rtl">
                <router-link to="/privacy-policy">
                    - سياسة الخصوصية -
                </router-link>
            </div>
            <div class="w-full mb-20">
                <div class="w-full flex items-center justify-center">
                    <img class="w-auto h-11" src="/public/img/profile_image.png" alt="profile-image">
                </div>
                <div class="text-center mt-4">
                    <p class="text-sm text-[#408E58]">
                        لديك حساب مسجل من قبل.. قم بعملية الدخول
                    </p>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<style scoped></style>
