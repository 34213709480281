<script setup>
import { useProductsStore } from '@/stores/products.store';

defineProps({
    categoryData: Object,
    selectedCategory: Number,
    isFastDelivery: {
        type: Boolean,
        default: false
    },
});

function emptySearch() {
    useProductsStore().emptySearch();
}

</script>
<template>
    <a :href="'#' + categoryData.name" data-link="category-link" @click="emptySearch">
        <div v-if="isFastDelivery ? isFastDelivery && categoryData.is_fastdelivery : categoryData.is_fastdelivery === false"
            class="p-2 select-none cursor-pointer rounded-2xl  mix-w-40 min-w-40 mix-h-20 min-h-20  bg-cover " :class="{
                'border border-tazej-gold-primary shadow-master ': (categoryData.id == selectedCategory)
            }" :style="{
                backgroundImage: categoryData.cover_image
                    ? `linear-gradient(to bottom, #0000 20%, #408E58 100%), url('data:image/png;base64,${categoryData.cover_image}')`
                    : `linear-gradient(to bottom, #0000 20%, #408E58 100%) ,url('/public/img/categoryImage.png')`
            }">
            <div class="rounded-md p-2 w-full h-full flex items-end justify-start ">
                <h3 class="text-center text-sm text-white font-bold my-2">{{ categoryData.name }}</h3>
            </div>
        </div>
    </a>
</template>