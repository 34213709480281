<script setup>
import GreenButton from '@/components/GreenButton.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import userService from '@/service/userService';
import { useAuthStore } from '@/stores/auth.store';

const authStore = useAuthStore();
const userAddress = ref([]);
const router = useRouter();

async function getUserAddresses() {

    userAddress.value = await userService.getUserAddresses(authStore.userData.id);
    if (userAddress?.value?.data?.result?.status === "error") {
        userAddress.value = [];
    } else {
        userAddress.value = userAddress?.value?.data?.result;
    }

}

function goToMap() {
    router.push("/map");
}

async function setDefaultAddress(id) {

    const setAsDefault = await userService.setDefaultAddress({
        "id": id,
        "customer_id": authStore.userData.id,
    })

    if (setAsDefault.data.result.message === "Address set as default successfully") {
        getUserAddresses();
    }
}

async function deleteAddress(id) {
    const deleteAddress = await userService.deleteAddress(id)

    if (deleteAddress.data.result.message === "Address deactivated successfully") {
        getUserAddresses();
    }
}
onMounted(() => {
    getUserAddresses();
});
</script>
<template>
    <AppLayout :nav-bar-status="false" :back-home-status="true" title="العناوين">
        <div class="">
            <!-- Content -->
            <div class="px-4 py-6">
                <div class="flex items-center  border-b border-gray-300 pb-2">
                    <button @click="goToMap"
                        class="w-6 h-6 bg-tazej-green-primary rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-tazej-gray-200" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <circle cx="12" cy="12" r="9" stroke="currentColor" fill="none" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v8m4-4H8" />
                        </svg>
                    </button>

                    <span class="text-gray-700 mr-2">أضف عنوان توصيل جديد</span>

                </div>

                <div class="mt-8 text-center text-gray-500">
                    <div v-if="userAddress.length === 0">
                        لا توجد عناوين مسجلة
                    </div>
                    <div class=" p-4 flex flex-col space-y-2" v-else v-for="address in userAddress" :key="address.id">
                        <!-- Header -->
                        <div class="flex items-center space-x-2 rtl:space-x-reverse">
                            <span class="text-gray-700 font-bold">المنزل</span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-700" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path d="M10 20v-6h4v6h5v-9h3L12 3 2 11h3v9z" fill="none" stroke-linejoin="round"
                                    stroke-linecap="round" />
                            </svg>
                        </div>
                        <!-- Address Details -->
                        <p class="text-gray-500 text-sm leading-5">
                            {{ address.street }}
                        </p>

                        <!-- Buttons -->
                        <div class="flex space-x-2 rtl:space-x-reverse">
                            <GreenButton @click="setDefaultAddress(address.id)" class="relative">

                                <svg v-if="address.is_default_add" xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 absolute top-1 left-1" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M9 12l2 2l4-4m5 3a9 9 0 11-18 0a9 9 0 0118 0z" />
                                </svg>
                                <span>اختيار كعنوان توصيل</span>
                            </GreenButton>
                            <button class="bg-red-100 text-red-600 px-4 py-2 rounded-md text-sm hover:bg-red-200"
                                @click="deleteAddress(address.id)">
                                مسح
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </AppLayout>
</template>
<style>
/* No additional styles needed, Tailwind handles everything */
</style>