import axios from "axios";

class MainService {
    static session = null;
    static apiUrl = import.meta.env.MODE === 'development' ? import.meta.env.VITE_DEV_URL : import.meta.env.VITE_API_URL;

    constructor() {


    }

    static async handleSession() {
        await MainService.getSession();
    }

    static createUrl(endpoint = "") {
        return `${MainService.apiUrl}/${endpoint}`;
    }

    static createPayload(params) {
        return {
            jsonrpc: "2.0",
            method: "call",
            params: params,
            'id': null
        };
    }

    static async getSession() {
        const db = "TAZEJ_FOOD";
        const username = "admin";
        const password = "HxW7TQCKTaUBxrL";

        const authPayload = {
            jsonrpc: '2.0',
            method: 'call',
            params: {
                db: db,
                login: username,
                password: password,
            },
            id: null
        };

        try {
            // Create an axios instance with withCredentials to handle cookies
            MainService.session = axios.create({
                baseURL: MainService.apiUrl,
                withCredentials: true, // Important for cookie handling
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            await MainService.post(`web/session/authenticate`, authPayload);

        } catch (error) {
            console.error('Error starting session:', error);
            throw error;
        }
    }

    static get(url, headers) {
        return new Promise(async (res, rej) => {
            try {

                const config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: MainService.createUrl(url),
                    headers: headers,
                };

                const response = await MainService.session.request(config);
                if (response.data.error) {
                    console.error('Request failed:', response.data.error);
                    res({
                        success: false,
                        error: response.data.error,
                        data: null,
                    });
                }

                res({
                    success: true,
                    data: response.data,
                    error: null
                });
            } catch (error) {
                console.error('Error during Request:', error);
                rej({
                    success: false,
                    error: error.message,
                    data: null,

                });
            }

        });

    }

    static post(url, body = {}, headers = {}) {

        return new Promise(async (res, rej) => {
            try {
                const config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: MainService.createUrl(url),
                    headers: headers,
                    data: body
                };

                const response = await MainService.session.request(config);

                if (response.data.error) {
                    console.error('Request failed:', response.data.error);
                    res({
                        success: false,
                        error: response.data.error,
                        data: null,
                    });
                }

                res({
                    success: true,
                    data: response.data,
                    error: null
                });
            } catch (error) {
                console.error('Error during Request:', error);
                rej({
                    success: false,
                    error: error.message,
                    data: null,

                });
            }

        });

    }

    static async getSlider(version = "2.8", platform = "ios") {
        return await MainService.post('sales/slider', {
            "version": version,
            "platform": platform

        });
    }
}





export default MainService;