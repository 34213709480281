<template>
    <AppLayout>
        <div>
            <Slider />
        </div>
        <div class="px-4 md:px-20 lg:px-40 my-12">
            <div :class="{ invisible: !showNormalCategoriesNav }"
                class="flex flex-col items-center justify-center gap-4 mt-10 overflow-x-auto sm:overflow-x-visible">
                <div class="flex items-start justify-start w-full px-6">
                    <h2 class="text-gray-500 font-medium text-2xl">التصنيفات</h2>
                </div>
                <div class="grid grid-cols-2 i gap-5 justify-items-center place-items-center"
                    id="normal-categories-nav">
                    <CategoryItem :is-fast-delivery="false" v-for="category in productsStore.categories"
                        :key="category.id" @click="selectCategory(category.id)" :category-data="category"
                        :selected-category="productsStore.selectedCategoryId" class="min-w-36 max-w-36" />
                </div>
            </div>
            <Transition>
                <nav v-show="showStickyCategoriesNav" style="top: 60px"
                    class="overflow-y-auto bg-white fixed w-full z-10 start-0 border-b border-gray-200">
                    <div class="flex flex-wrap items-center justify-between mx-auto py-4 px-4 md:px-20 lg:px-40">
                        <div class="flex items-center gap-4 justify-between md:justify-start w-full md:w-auto">
                            <NavCategoryItem v-for="category in productsStore.categories" :key="category.id"
                                @click="handleSelectCategory(category.id)" :category-data="category"
                                :selected-category="productsStore.selectedCategoryId" />
                        </div>
                    </div>
                </nav>
            </Transition>

            <div v-for="(category, index) in filteredProducts" class="my-12" :id="category.name"
                :data-custom-id="'number-' + index" :data-category-id="category.id">
                <div class="mt-6 mb-4 px-6 font-medium text-gray-500 text-2xl">
                    {{ category.name }}
                </div>
                <div class="grid grid-cols-3 place-items-center justify-items-center">
                    <ProductItem v-for="product in category.categ_ids" :key="product.id" :product-data="product"
                        @click="openModal(product)" />
                </div>
            </div>
        </div>
        <ProductDetails :show="isModalVisible" :max-width="'md'" :closeable="true" :product="selectedProduct"
            @close="isModalVisible = false" />
    </AppLayout>
</template>

<script setup>
import AppLayout from "@/layouts/AppLayout.vue";
import Spinner from "@/components/Spinner.vue";
import CategoryItem from "@/components/CategoryItem.vue";
import ProductItem from "@/components/ProductItem.vue";
import NavCategoryItem from "@/components/NavCategoryItem.vue";
import ProductDetails from "@/components/ProductDetails.vue";
import { useProductsStore } from "@/stores/products.store";
import { computed, ref, watch } from "vue";
import Slider from "@/components/Slider.vue";

const productsStore = useProductsStore();
const selectCategory = (id) => {
    productsStore.selectedCategoryId = id;
};
let isScrolling = false;
watch(
    () => productsStore.categories,
    async () => {
        await nextTick();
        handleCategoryScroll();
        handelSelectedCategoryWithScrolling();
    }
);

function handleSelectCategory(id) {
    isScrolling = false;
    selectCategory(id);
    setTimeout(() => {
        isScrolling = true;
    }, 1000);
}
const isModalVisible = ref(false);
const selectedProduct = ref(null);
const openModal = (product) => {
    if (
        (product?.variants && product?.variants[0]?.has_cut) ||
        product?.variants[0]?.has_options
    ) {
        selectedProduct.value = product;
        isModalVisible.value = true;
    }
};

const filteredProducts = computed(() => {
    const searchQuery = productsStore.searchQuery.trim();
    let categoryProduct = productsStore.allCategories;

    if (searchQuery && searchQuery.length > 0 && searchQuery !== "") {
        const result = [];

        for (let i = 0; i < categoryProduct.length; i++) {
            const searchedProduct = [];
            const cat = JSON.parse(JSON.stringify(categoryProduct[i]));
            cat.categ_ids.forEach((product) => {
                if (product.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                    searchedProduct.push(product);
                }
            });

            if (searchedProduct.length > 0) {
                cat.categ_ids = searchedProduct;
                result.push(cat);
            }
        }
        return result;
    } else {
        return categoryProduct;
    }
});

const showNormalCategoriesNav = ref(true);
const showStickyCategoriesNav = ref(false);

function handelSelectedCategoryWithScrolling() {
    document.querySelectorAll('[data-custom-id^="number-"]').forEach((category) => {
        if (category) {
            sectionsPosition[category.getAttribute("data-category-id")] = {
                start: category.getBoundingClientRect().top - 140,
                end: category.getBoundingClientRect().bottom - 140,
            };
        }
    });
}
function handleScroll() {
    const elementPosition = getPosition(document.getElementById("normal-categories-nav")).y;

    if (
        elementPosition &&
        window.scrollY > elementPosition + 200 &&
        window.scrollY > elementPosition + 400
    ) {
        showNormalCategoriesNav.value = false;
        showStickyCategoriesNav.value = true;
    } else {
        showNormalCategoriesNav.value = true;
        showStickyCategoriesNav.value = false;
    }
}

function getPosition(element) {
    var rect = element.getBoundingClientRect();
    return {
        x: rect.left,
        y: rect.top,
    };
}

const sectionsPosition = {};
function handleCategoryScroll() {
    document.querySelectorAll('[data-link="category-link"]').forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
            e.preventDefault();

            const targetId = this.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const targetPosition = targetElement.getBoundingClientRect().top;
                const scrollPosition = window.scrollY + targetPosition - 200;

                window.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            }
        });
    });
}
function selectNavbarWithScrolling() {
    if (isScrolling) {
        Object.entries(sectionsPosition).forEach(([key, value]) => {
            if (window.scrollY > value.start && window.scrollY < value.end) {
                setTimeout(() => {
                    selectCategory(Number(key));
                }, 500);
            }
        });
    }
}
onMounted(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", selectNavbarWithScrolling);
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", handleScroll);
    window.removeEventListener("scroll", selectNavbarWithScrolling);
});
</script>

<style scoped>
#main-card {
    background-image: url("/public/img/Banner.png");
    background-size: cover;
}

.card-overlay {
    height: 181px;
}

.card-overlay {
    height: 181px;
}

@media (min-width: 412px) and (max-width: 932px) {
    .card-overlay {
        height: 200px;
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
