<template>
    <a :href="'#' + categoryData.name" data-link="category-link" @click="emptySearch">
        <div class="select-none cursor-pointer  min-w-24 flex flex-col p-1 items-center ">
            <img :class="{
                'border border-tazej-gold-primary  rounded-md shadow-master': (categoryData.id == selectedCategory)
            }" class="w-full category-image-carved" :src="categoryData.cover_image
                ? 'data:image/png;base64,' + categoryData.cover_image
                : '/public/img/categoryImage.png'" alt="category-cover" draggable="false">
            <h3 :class="{
                'text-tazej-gold-primary': (categoryData.id == selectedCategory)
            }" class="text-center min-w-24 text-black font-medium my-2 mx-2 ">{{ categoryData.name }}
            </h3>
        </div>
    </a>
</template>

<script setup>
import { useProductsStore } from '@/stores/products.store';

defineProps({
    categoryData: Object,
    selectedCategory: Number,
});

function emptySearch() {
    useProductsStore().emptySearch();
}


</script>

<style scoped>
.category-image-carved {
    border-radius: 1.5rem;
}
</style>