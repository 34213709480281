<script setup>
import CollapsibleCard from '@/components/CollapsibleCard.vue';
import AppLayout from '@/layouts/AppLayout.vue'
import { useCartStore } from '@/stores/cart.store';
import { useProductsStore } from '@/stores/products.store';
import userService from '@/service/userService';
import { useAuthStore } from '@/stores/auth.store';
import GreenButton from '@/components/GreenButton.vue';
import orderService from '@/service/orderService';
import deliveryService from '@/service/deliveryService';
import SearchableSelect from '@/components/SearchableSelect.vue';
import Modal from '@/components/Modal.vue';
import { useToast } from "vue-toastification";
import moment from 'moment';

const toast = useToast();

const cartData = JSON.parse(localStorage.getItem('cart_tazj_product'));
const cart = ref({});
const cartStore = useCartStore();
const productStore = useProductsStore();
const userAddress = ref([]);
const authStore = useAuthStore();
const defaultAddress = ref("");
const router = useRouter();
const openSelectTime = ref(false);
const periods = ref([]);
const periodResult = ref([]);
const activeTab = ref('delivery');
const minDate = moment().add(1, 'days').format('YYYY-MM-DD');
const date = ref(minDate);
let selectedPeriod = null;
const neighborhoods = ref([]);
const charityOrganizations = ref([]);
const selectedNeighborhoods = ref(null);
const selectedChar = ref(null);
const coupon = ref("");
const giftRecipientName = ref("");
const giftSenderName = ref("");
const giftRecipientPhone = ref("");
const giftNote = ref("");
let isCouponValid = false;
const products = {}

const openCouponModal = ref(false);
const isProductStoreLoaded = ref(false);

async function getGiftData() {
    const giftResponse = await deliveryService.getGiftData(productStore.selectedAria.id);

    if (giftResponse?.data?.result) {
        neighborhoods.value = giftResponse.data.result.areas.map(area => {
            return {
                label: area.name,
                value: area.id
            }
        });
        charityOrganizations.value = giftResponse.data.result.charity_orgs.map(org => {
            return {
                label: org.name,
                value: org.id
            }
        });
    } else {
        // alert("something wrong with server, please try again later");
        toast.error("something wrong with server, please try again later");

    }
}

function selectTimeSlot(event) {
    selectedPeriod = event.target.value;

}

function changeDay(day) {
    const selectedDate = moment(day.id);
    const currentDate = moment(minDate);

    if (selectedDate.isBefore(currentDate, 'day')) {
        toast.warning("لا يمكنك الطلب في نفس اليوم أو قبله");
        return;
    }

    date.value = selectedDate.format('YYYY-MM-DD');
    getPeriod();
}

async function getPeriod() {
    productStore.turnOnLoading();
    const availablePeriods = await deliveryService.getAvailablePeriod(date.value, productStore.selectedAria.id);

    if (availablePeriods?.data?.result && availablePeriods?.data?.result?.length > 0) {
        periods.value = availablePeriods?.data?.result;
        if (await getAvailablePeriod()) {
            await checkCapacity();
            openSelectTime.value = true;
        } else {
            // alert("something went wrong, please try again later");
            toast.error("something went wrong, please try again later");

        }
    } else {
        // alert("you cant request in this day");
        toast.warning("لا يوجد أوقات متاحة في هذا اليوم");

    }

    productStore.turnOffLoading();


}

async function getAvailablePeriod() {
    return new Promise((resolve, reject) => {
        if (productStore?.delivery?.delivery_periods?.length > 0) {
            periodResult.value = productStore.delivery.delivery_periods.filter(period => periods.value.includes(period.id));
            resolve(true);
        } else {
            resolve(false);
        }
    });
}

async function checkCapacity() {
    // Check if the selected date is available
    for (let i = 0; i < periodResult.value.length; i++) {
        const period = periodResult.value[i];
        const areaCapacityPerPeriod = await deliveryService.checkAreaCapacity(date.value, productStore.selectedAria.id, period.id);
        if (areaCapacityPerPeriod?.data?.result === 0) {
            periodResult.value[i].available = true;
        } else {
            periodResult.value[i].available = false;
        }
    }
}

function countProducts() {
    for (let i = 0; i < cartData.length; i++) {
        const element = cartData[i];
        if (!!cart.value[element.id]) {
            cart.value[element.id].count += 1;
            cart.value[element.id].price += element.minimum_price;
        } else {
            cart.value[element.id] = {
                count: 1,
                price: element.minimum_price,
                name: element.name,
                data: element
            }
        }
    }
}

async function getUserAddresses() {

    userAddress.value = await userService.getUserAddresses(authStore.userData.id);
    if (userAddress?.value?.data?.result?.status === "error") {
        userAddress.value = [];
    } else {
        userAddress.value = userAddress?.value?.data?.result;
        userAddress.value.forEach(address => {
            if (address.is_default_add === true) {
                defaultAddress.value = address;
            }
        });

    }

}

function increaseProducts(id) {
    cart.value[id].count += 1;
    cart.value[id].price += cart.value[id].data.minimum_price;
    cartData.push(cart.value[id].data);
    cartStore.addToCart(cart.value[id].data);
    localStorage.setItem('cart_tazj_product', JSON.stringify(cartData));

}

function decreaseProducts(id) {
    if (cart.value[id].count > 1) {
        cart.value[id].count -= 1;
        cart.value[id].price -= cart.value[id].data.minimum_price;

        removeOneProductById(id);
        cartStore.removeFromCart(cart.value[id].data);
        localStorage.setItem('cart_tazj_product', JSON.stringify(cartData));
    }
}

function removeOneProductById(id) {
    const index = cartData.findIndex(item => item.id == id);

    if (index !== -1) {
        cartData.splice(index, 1);
    }

}
function changeDeliveryAddress() {
    router.push("/addresses");
}

function validation() {
    let condition = true;


    const giftInputs = {
        giftRecipientName: { value: giftRecipientName.value, message: "يجب كتابة اسم المستلم" },
        giftSenderName: { value: giftSenderName.value, message: "يجب كتابة اسم المرسل" },
        giftRecipientPhone: { value: giftRecipientPhone.value, message: "يجب كتابة رقم هاتف المستلم" },
        selectedNeighborhoods: { value: selectedNeighborhoods.value, message: "يجب اختيار الحي" },
        giftNote: { value: giftNote.value, message: "يجب كتابة ملاحظة" },

    };

    const donationInputs = {
        selectedChar: { value: selectedChar.value, message: "يجب اختيار جمعية خيرية" }
    }

    if (date.value && selectedPeriod) {
        switch (activeTab.value) {
            case 'gift':
                for (const key in giftInputs) {
                    const element = giftInputs[key];
                    if (element.value == null || element.value == "") {
                        condition = false;
                        // alert(element.message);
                        toast.warning(element.message);

                    }
                }
                break;
            case 'donation':
                for (const key in donationInputs) {
                    const element = giftInputs[key];
                    if (element.value == null || element.value == "") {
                        condition = false;
                        // alert(element.message);
                        toast.warning(element.message);

                    }
                }
                break;
            default:
                break;
        }

    } else {
        condition = false;
        // alert("يجب اختيار التاريخ والوقت");
        toast.warning("يجب اختيار التاريخ والوقت");

    }
    return condition;
}


function handleSendBuyRequest() {
    if (validation()) {
        switch (activeTab.value) {
            case 'delivery':
                sendBuyRequest();
                break;
            case 'gift':
                sendGiftBuyRequest();
                break;
            case 'donation':
                sendDonationBuyRequest();
                break;
            default:
                break;
        }

    }
}

function handleProducts() {
    cartData.forEach(item => {
        let cut_id = 0;
        if (item?.variants[0]?.cut_ids) {
            cut_id = item?.variants[0]?.cut_ids[0] ?? 0;
        } else {
            cut_id = item?.variants[0]?.cut_id?.id ? item?.variants[0]?.cut_id?.id : 0;
        }

        products[item?.variants[0]?.id] = {
            product_id: item?.variants[0]?.id,
            product_name: item?.variants[0]?.name ?? item?.name,
            qty: cart.value[item.id].count,
            cut_id: cut_id,
            option_ids: [],
            notes: "",
        }
    });
}

async function sendBuyRequest() {

    const order = await orderService.addNewOrder({
        partner_id: authStore.userData.id,
        address_id: defaultAddress.value.id,
        coupon: isCouponValid ? coupon.value : "",
        products: Object.values(products),
        delivery_day: date.value + "00:00:00",
        date: date.value + "00:00:00",
        payment_method: "cash",
        delivery_period: selectedPeriod,
    });

    if (order?.data?.result?.result === "success") {
        cartStore.clearCart();
        router.push("/");
    } else { }

}

async function sendGiftBuyRequest() {

    const order = await orderService.addNewGiftOrder({
        partner_id: authStore.userData.id,
        coupon: isCouponValid ? coupon.value : "",
        neighborhood_id: selectedNeighborhoods.value,
        gift_recipient: giftRecipientName.value,
        gift_sender: giftSenderName.value,
        recipient_phone: giftRecipientPhone.value,
        gift_note: giftNote.value,
        products: Object.values(products),
        delivery_day: date.value + "00:00:00",
        date: date.value + "00:00:00",
        payment_method: "cash",
        delivery_period: selectedPeriod,
    });

    if (order?.data?.result?.result === "success") {
        cartStore.clearCart();
        router.push("/");
    } else { }

}

async function sendDonationBuyRequest() {

    const order = await orderService.addNewDonationOrder({
        partner_id: authStore.userData.id,
        coupon: isCouponValid ? coupon.value : "",
        charity_org_id: selectedChar.value,
        products: Object.values(products),
        delivery_day: date.value + "00:00:00",
        date: date.value + "00:00:00",
        payment_method: "cash",
        delivery_period: selectedPeriod,
    });

    if (order?.data?.result?.result === "success") {
        cartStore.clearCart();
        router.push("/");
    } else { }

}

async function checkCoupon() {
    const productIds = [];

    for (const key in products) {
        const element = products[key];
        productIds.push(element.product_id);
    }
    productStore.turnOnLoading();

    const couponResponse = await orderService.checkCoupon({ code: coupon.value, partner_id: authStore.userData.id }, productIds);
    if (!couponResponse?.data?.result?.valid) {
        toast.warning(couponResponse?.data?.result?.error_message);
    } else {
        isCouponValid = true;
        openCouponModal.value = false;
        toast.success("تم تفعيل الكوبون بنجاح");
    }
    productStore.turnOffLoading();

}

// Watch for productStore data to be loaded
watch(
    () => productStore.delivery.delivery_fees,
    (newVal) => {
        if (newVal) {
            isProductStoreLoaded.value = true;
        }
    },
    { immediate: true }
);

const totalMinPrice = computed(() => {
    if (!isProductStoreLoaded.value) return 0;
    let total = 0;
    for (const key in cart.value) {
        const element = cart.value[key];
        total += element.price;
    }
    return parseFloat(total).toFixed(2);
});

const deliveryPrice = computed(() => {
    if (!isProductStoreLoaded.value) return 0;

    const freeDelivery = productStore.delivery?.delivery_fees.filter(fee => fee.name === "delivery fee")[0];
    return Number(totalMinPrice.value) > freeDelivery.amount ? 0 : freeDelivery.fixed_price;
});

const taxPrice = computed(() => {
    if (!isProductStoreLoaded.value) return 0;
    return parseFloat(((Number(totalMinPrice.value) + deliveryPrice.value) * 0.15).toFixed(2));
});

const totalPrice = computed(() => {
    if (!isProductStoreLoaded.value) return 0;
    return parseFloat(Number(totalMinPrice.value) + deliveryPrice.value + taxPrice.value).toFixed(2);
});

onMounted(() => {
    countProducts();
    getUserAddresses();
    handleProducts();
    setTimeout(() => {
        getGiftData();
    });
});

</script>

<template>
    <AppLayout>

        <Modal :isOpen="openCouponModal" @close="openCouponModal = false">
            <template v-slot:header>
                <div class="p-4">
                    <h2 class="text-xl text-white font-bold mb-4">كوبون الخصم</h2>
                </div>
            </template>
            <template v-slot:body>
                <input type="text" placeholder="كوبون الخصم" class="w-full border rounded" v-model="coupon">

            </template>
            <template v-slot:footer>
                <div class="w-full">

                    <div class="mb-5 w-full">
                        <button @click="checkCoupon" class="bg-tazej-green-primary text-white w-full">أضف كوبون
                            الخصم</button>
                    </div>
                </div>
            </template>
        </Modal>

        <CollapsibleCard title="المنتجات">
            <template v-slot:body>
                <div class="flex justify-between " v-for="(item, id) in cart">
                    <div class="text-right">
                        <span class="block">{{ item.name }}</span>
                        <div class=" my-5">
                            <span class="bg-[#d1d1d1]">{{ item.price.toFixed(2) }} ريال</span>
                        </div>
                    </div>
                    <div class="flex col-row">
                        <svg @click="increaseProducts(id)" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 cursor-pointer"
                            preserveAspectRatio="xMidYMid meet">
                            <rect width="24" height="24" rx="8" fill="#408E58"></rect>
                            <circle cx="12" cy="12" r="7.5" stroke="white"></circle>
                            <path
                                d="M11.4872 12.5128H8.06836C7.92306 12.5128 7.8013 12.4636 7.70306 12.3653C7.60472 12.2669 7.55554 12.1451 7.55554 11.9998C7.55554 11.8544 7.60472 11.7326 7.70306 11.6345C7.8013 11.5363 7.92306 11.4872 8.06836 11.4872H11.4872V8.06836C11.4872 7.92306 11.5363 7.8013 11.6347 7.70306C11.733 7.60472 11.8549 7.55554 12.0002 7.55554C12.1456 7.55554 12.2673 7.60472 12.3655 7.70306C12.4637 7.8013 12.5128 7.92306 12.5128 8.06836V11.4872H15.9316C16.0769 11.4872 16.1987 11.5363 16.2969 11.6347C16.3953 11.733 16.4444 11.8549 16.4444 12.0002C16.4444 12.1456 16.3953 12.2673 16.2969 12.3655C16.1987 12.4637 16.0769 12.5128 15.9316 12.5128H12.5128V15.9316C12.5128 16.0769 12.4636 16.1987 12.3653 16.2969C12.2669 16.3953 12.1451 16.4444 11.9998 16.4444C11.8544 16.4444 11.7326 16.3953 11.6345 16.2969C11.5363 16.1987 11.4872 16.0769 11.4872 15.9316V12.5128Z"
                                fill="white"></path>
                        </svg>
                        <span class="mx-2">{{ item.count }}</span>
                        <svg @click="decreaseProducts(id)" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 cursor-pointer"
                            preserveAspectRatio="xMidYMid meet">
                            <rect width="24" height="24" rx="8" fill="#408E58"></rect>
                            <circle cx="12" cy="12" r="7.5" stroke="white"></circle>
                            <path
                                d="M7.57692 13C7.41346 13 7.27647 12.9521 7.16596 12.8562C7.05532 12.7603 7 12.6415 7 12.4998C7 12.3581 7.05532 12.2393 7.16596 12.1437C7.27647 12.0479 7.41346 12 7.57692 12H16.4231C16.5865 12 16.7235 12.0479 16.834 12.1438C16.9447 12.2397 17 12.3585 17 12.5002C17 12.6419 16.9447 12.7607 16.834 12.8563C16.7235 12.9521 16.5865 13 16.4231 13H7.57692Z"
                                fill="white"></path>
                        </svg>
                    </div>
                </div>
            </template>
        </CollapsibleCard>


        <div class="border border-gray-300 rounded-lg overflow-hidden w-90 m-2" @click="openCouponModal = true">
            <div class="bg-gray-100 px-4 py-2 cursor-pointer flex items-center">
                <svg class="ml-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="25" height="25">
                    <circle cx="50" cy="50" r="45" stroke="gray" stroke-width="2" fill="none" />
                    <line x1="50" y1="20" x2="50" y2="80" stroke="gray" stroke-width="2" />
                    <line x1="20" y1="50" x2="80" y2="50" stroke="gray" stroke-width="2" />
                </svg>
                <span>أضف كوبون خصم</span>
            </div>

        </div>

        <div class="border border-gray-300 rounded-lg overflow-hidden w-90 m-2">
            <div class="bg-gray-100 px-4 py-2 flex items-center justify-between">
                <div class="flex flex-col ">
                    <span>الإجمالي قبل الضريبة</span>
                    <span>رسوم التوصيل</span>
                    <span>ضريبة القيمة المضافة</span>
                    <span>الإجمالي</span>
                </div>
                <div>
                    <div>
                        <div class="flex justify-between">
                            <span>{{ totalMinPrice }}&nbsp; &nbsp;</span>
                            <span>ر.س</span>
                        </div>
                        <div class="flex justify-between">
                            <span>{{ deliveryPrice }}&nbsp; &nbsp;</span>
                            <span>ر.س</span>
                        </div>
                        <div class="flex justify-between">
                            <span>{{ taxPrice }}&nbsp; &nbsp;</span>
                            <span>ر.س</span>
                        </div>
                        <div class="flex justify-between">
                            <span>{{ totalPrice }}&nbsp; &nbsp;</span>
                            <span>ر.س</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <CollapsibleCard title="عنوان التوصيل" :extension="false" :collapse="true">
            <template v-slot:body>
                <div class="flex flex-col ">
                    <div>
                        الموقع الحالي
                    </div>
                    <div>
                        {{ defaultAddress.street }}
                    </div>
                    <div class="my-5">
                        <GreenButton @click="changeDeliveryAddress" style="margin:0px">
                            <span>تغيير عنوان التوصيل</span>
                        </GreenButton>
                    </div>
                </div>
            </template>
            <template v-slot:extension>
                <div class="w-full max-w-md  p-6 ">
                    <h2 class="text-xl font-semibold text-center mb-4">تأكيد الطلب</h2>
                    <div class="flex mb-4 ">
                        <button @click="activeTab = 'delivery'"
                            :class="{ 'bg-tazej-lightGreen-primary text-white': activeTab === 'delivery', 'bg-white text-gray-700': activeTab !== 'delivery' }"
                            class="flex-1 py-2 border-r border rounded ">توصيل</button>
                        <!-- <button @click="activeTab = 'gift'"
                            :class="{ 'bg-tazej-lightGreen-primary text-white': activeTab === 'gift', 'bg-white text-gray-700': activeTab !== 'gift' }"
                            class="flex-1 py-2 border-r border rounded mx-2">هدية</button>
                        <button @click="activeTab = 'donation'"
                            :class="{ 'bg-tazej-lightGreen-primary text-white': activeTab === 'donation', 'bg-white text-gray-700': activeTab !== 'donation' }"
                            class="flex-1 py-2 border rounded ">تبرع</button> -->
                    </div>

                    <div v-if="activeTab === 'gift'" class="space-y-3">
                        <input type="text" placeholder="اسم المستلم" class="w-full px-3 py-2 border rounded"
                            v-model="giftRecipientName">
                        <input type="text" placeholder="اسم المرسل" class="w-full px-3 py-2 border rounded"
                            v-model="giftSenderName">
                        <input type="text" placeholder="رقم المستلم" class="w-full px-3 py-2 border rounded"
                            v-model="giftRecipientPhone">


                        <SearchableSelect v-model="selectedNeighborhoods" :options="neighborhoods" placeholder="الحي" />


                        <textarea placeholder="الرسالة" class="w-full px-3 py-2 border rounded"
                            v-model="giftNote"></textarea>
                    </div>

                    <div v-if="activeTab === 'donation'" class="space-y-3 mb-5">
                        <SearchableSelect v-model="selectedChar" :options="charityOrganizations"
                            placeholder="الجمعية الخيرية" />
                    </div>

                </div>
            </template>
        </CollapsibleCard>
        <CollapsibleCard title="التاريخ">
            <template v-slot:body>
                <v-date-picker @dayclick="changeDay" locale="ar" style="width:100%" :min-date="minDate" />
                <div v-if="openSelectTime" class="text-tazej-green-dark my-5">
                    <div v-for="timeSlotPeriod in periodResult" class="flex items-center mt-5">
                        <input type="radio" name="timeSlot" :id="timeSlotPeriod.id" :value="timeSlotPeriod.id"
                            @click="selectTimeSlot" :disabled="!timeSlotPeriod.available"
                            class="disabled:bg-gray-200 disabled:border-gray-400 disabled:text-gray-500 disabled:cursor-not-allowed ">
                        <span class="mr-5">{{ timeSlotPeriod.name }}</span>

                    </div>

                </div>
            </template>
        </CollapsibleCard>
        <CollapsibleCard title="طريقة الدفع">
            <template v-slot:body>
                <div class="flex items-center ">
                    <label class="ml-5">
                        <input type="radio" name="payment" value="cash" checked />
                    </label>
                    <div class="flex flex-col items-center">
                        <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                            <path
                                d="M45.5 33.36h-43a1 1 0 0 1-1-1V29a1 1 0 0 1 1-1h43a1 1 0 0 1 1 1v3.39a1 1 0 0 1-1 .97zm-42-2h41V30h-41z" />
                            <path
                                d="M45.5 36.74h-43a1 1 0 0 1-1-1v-3.38a1 1 0 0 1 1-1h43a1 1 0 0 1 1 1v3.38a1 1 0 0 1-1 1zm-42-2h41v-1.38h-41z" />
                            <path
                                d="M45.5 40.12h-43a1 1 0 0 1-1-1v-3.38a1 1 0 0 1 1-1h43a1 1 0 0 1 1 1v3.38a1 1 0 0 1-1 1zm-42-2h41v-1.38h-41zM45.5 30h-43a1 1 0 0 1-1-1V8.88a1 1 0 0 1 1-1h43a1 1 0 0 1 1 1V29a1 1 0 0 1-1 1zm-42-2h41V9.88h-41z" />
                            <path
                                d="M42.51 17.31a1.24 1.24 0 0 1-.27 0c-2.9-.8-4.74-2.65-5.19-5.21a1 1 0 0 1 1-1.17h4.47a1 1 0 0 1 1 1v4.41a1 1 0 0 1-1 1zM39.4 12.9a4.48 4.48 0 0 0 2.11 2v-2zM42.51 27H38a1 1 0 0 1-1-1.17c.45-2.56 2.29-4.41 5.19-5.21a1 1 0 0 1 1.27 1V26a1 1 0 0 1-.95 1zm-3.11-2h2.11v-2a4.48 4.48 0 0 0-2.11 2zM10 27H5.49a1 1 0 0 1-1-1v-4.45a1 1 0 0 1 .39-.8 1 1 0 0 1 .87-.17c2.9.8 4.75 2.65 5.19 5.21A1 1 0 0 1 10 27zm-3.51-2h2.1a4.46 4.46 0 0 0-2.1-2zM5.49 17.31a1 1 0 0 1-.61-.2 1 1 0 0 1-.39-.8V11.9a1 1 0 0 1 1-1H10a1 1 0 0 1 1 1.17c-.44 2.57-2.29 4.41-5.19 5.21a1.09 1.09 0 0 1-.32.03zm1-4.41v2a4.46 4.46 0 0 0 2.1-2zM24 27.06a8.13 8.13 0 1 1 8.13-8.13A8.14 8.14 0 0 1 24 27.06zm0-14.26a6.13 6.13 0 1 0 6.13 6.13A6.14 6.14 0 0 0 24 12.8z" />
                            <path
                                d="M24 19.93c-2.08 0-3.65-1.09-3.65-2.53s1.57-2.53 3.65-2.53c1.9 0 3.39.9 3.62 2.2a1 1 0 0 1-2 .35 2.08 2.08 0 0 0-1.62-.55 2.08 2.08 0 0 0-1.65.55 2.3 2.3 0 0 0 1.65.51 1 1 0 0 1 0 2z" />
                            <path
                                d="M24 23c-1.87 0-3.39-.93-3.62-2.2a1 1 0 0 1 2-.35A2.08 2.08 0 0 0 24 21a2 2 0 0 0 1.65-.56 2.32 2.32 0 0 0-1.65-.5 1 1 0 0 1 0-2c2.08 0 3.65 1.08 3.65 2.53S26.08 23 24 23z" />
                            <path d="M24 24.77a1 1 0 0 1-1-1v-9.68a1 1 0 0 1 2 0v9.68a1 1 0 0 1-1 1z" />
                        </svg>
                        <span class="text-[12px]">
                            الدفع عند الاستلام
                        </span>
                    </div>
                </div>
            </template>
        </CollapsibleCard>
        <div class="w-full px-5 pt-10">
            <GreenButton @click="handleSendBuyRequest" style="margin:0px">
                <span>تأكيد الطلب</span>
            </GreenButton>

        </div>
    </AppLayout>
</template>

<style scoped>
:deep(.vc-title) {
    font-size: 1.2em;
    font-weight: bold;
}

:deep(.vc-weekday) {
    font-weight: bold;
    color: #408e58;
}

:deep(.vc-day-content.is-selected) {
    background-color: #408e58 !important;
    color: white !important;
}

:deep(.vc-day-content:hover) {
    background-color: #408e5820 !important;
}

:deep(.vc-highlight) {
    background-color: #408e58 !important;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* Adds spacing between radio buttons */
}

input[type="radio"]:not(:disabled) {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid green;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
}

.disabled\:bg-gray-200:disabled {
    background-color: gray
}

input[type="radio"]:disabled {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(77, 77, 77);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
}

input[type="radio"]:checked:not(:disabled) {
    background-color: green;
}
</style>